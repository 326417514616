import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'
import {ChartModel} from '../../../../../_metronic/partials/widgets/charts/_models'
import {Assignment, AssignmentAddItem, AssignmentsQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const GET_ASSIGNMENT_URL = `${API_URL}/assignment/getassignment`
const GET_ASSIGNMENT_COUNT_CHART_URL = `${API_URL}/assignment/getassignmentcountchart`
const GET_ASSIGNMENT_LESSON_COUNT_CHART_URL = `${API_URL}/assignment/getassignmentlessoncountchart`
const CREATE_ASSIGNMENT_URL = `${API_URL}/assignment/addassignment`
const UPDATE_ASSIGNMENT_URL = `${API_URL}/assignment/updateassignment`
const DELETE_ASSIGNMENT_URL = `${API_URL}/assignment/deleteassignment`
const GET_ASSIGNMENTS_URL = `${API_URL}/assignment/getassignments`
const GET_CUSTOMER_ASSIGNMENTS_URL = `${API_URL}/assignment/getcustomerassignments`

const getAssignments = (query: string): Promise<AssignmentsQueryResponse> => {
  return axios
    .get(`${GET_ASSIGNMENTS_URL}?${query}`)
    .then((d: AxiosResponse<AssignmentsQueryResponse>) => d.data)
}

const getCustomerAssignments = (query: string): Promise<AssignmentsQueryResponse> => {
  return axios
    .get(`${GET_CUSTOMER_ASSIGNMENTS_URL}?${query}`)
    .then((d: AxiosResponse<AssignmentsQueryResponse>) => d.data)
}

const getAssignmentById = (id: ID): Promise<AssignmentAddItem | undefined> => {
  return axios
    .get(`${GET_ASSIGNMENT_URL}/${id}`)
    .then((response: AxiosResponse<Response<AssignmentAddItem>>) => response.data)
    .then((response: Response<AssignmentAddItem>) => response.data)
}

const getAssignmentCountChart = (query: string): Promise<ChartModel | undefined> => {
  return axios
    .get(`${GET_ASSIGNMENT_COUNT_CHART_URL}?${query}`)
    .then((response: AxiosResponse<Response<ChartModel>>) => response.data)
    .then((response: Response<ChartModel>) => response.data)
}

const getAssignmentLessonCountChart = (query: string): Promise<ChartModel | undefined> => {
  return axios
    .get(`${GET_ASSIGNMENT_LESSON_COUNT_CHART_URL}?${query}`)
    .then((response: AxiosResponse<Response<ChartModel>>) => response.data)
    .then((response: Response<ChartModel>) => response.data)
}

const createAssignment = (assignment: Assignment): Promise<Assignment | undefined> => {
  return axios
    .post(CREATE_ASSIGNMENT_URL, assignment)
    .then((response: AxiosResponse<Response<Assignment>>) => response.data)
    .then((response: Response<Assignment>) => response.data)
}

const updateAssignment = (assignment: AssignmentAddItem): Promise<Assignment | undefined> => {
  return axios
    .put(`${UPDATE_ASSIGNMENT_URL}/${assignment.id}`, assignment)
    .then((response: AxiosResponse<Response<AssignmentAddItem>>) => response.data)
    .then((response: Response<AssignmentAddItem>) => response.data)
}

const deleteAssignment = (assignmentId: ID): Promise<void> => {
  return axios.delete(`${DELETE_ASSIGNMENT_URL}/${assignmentId}`).then(() => {})
}

const deleteSelectedAssignments = (assignmentIds: Array<ID>): Promise<void> => {
  const requests = assignmentIds.map((id) => axios.delete(`${DELETE_ASSIGNMENT_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {getAssignments, getCustomerAssignments, deleteAssignment,getAssignmentLessonCountChart, getAssignmentCountChart, deleteSelectedAssignments, getAssignmentById, createAssignment, updateAssignment}
