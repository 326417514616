import {ID, Response} from '../../../../../_metronic/helpers'
export type City = {
    id?: ID
    
    name?: string
    countryId?: number
}

export type CitiesQueryResponse = Response<Array<City>>

export const initialCity: City = {
    
    name: '',
    countryId: 0
}

export const initialCities: City[] = [{
    
    name: '',
    countryId: 0
}]
