import {ID, Response} from '../../../helpers'
export type ChartModel = {
    series1?: Array<number>
    series2?: Array<number>
    categories?: Array<string>
}

export type ChartsQueryResponse = Response<ChartModel>

export const initialChartModel: ChartModel = {
    series1: [],
    series2: [],
    categories: []
}

