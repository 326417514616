import {FC} from 'react'
import {useLang} from './Metronici18n'
import {IntlProvider} from 'react-intl'
import '@formatjs/intl-relativetimeformat/polyfill'
import '@formatjs/intl-relativetimeformat/locale-data/en'
import '@formatjs/intl-relativetimeformat/locale-data/de'
import '@formatjs/intl-relativetimeformat/locale-data/fr'
import '@formatjs/intl-relativetimeformat/locale-data/tr'
import '@formatjs/intl-relativetimeformat/locale-data/ru'
import '@formatjs/intl-relativetimeformat/locale-data/ar'


import enMessages from './messages/en.json'
import trMessages from './messages/tr.json'
import ruMessages from './messages/ru.json'
import arMessages from './messages/ar.json'
import deMessages from './messages/de.json'
import frMessages from './messages/fr.json'
import { WithChildren } from '../helpers'

const allMessages = {
  en: enMessages,
  tr: trMessages,
  ru: ruMessages,
  ar: arMessages,
  de: deMessages,
  fr: frMessages
}

const I18nProvider: FC<WithChildren> = ({children}) => {
  const locale = useLang()
  const messages = allMessages[locale]

  return (
    <IntlProvider locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  )
}

export {I18nProvider}
