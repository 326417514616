

import {FC} from 'react'
import {Announcement} from '../core/_models'

import {useListView} from '../core/ListViewProvider'
import {useQueryResponse} from '../core/QueryResponseProvider'
import { useIntl } from "react-intl"
import {useAuth} from '../../../auth'
import {ImageWidget} from '../../../../../_metronic/partials/widgets'

type Props = {
  isAnnouncementLoading: boolean
  announcement: Announcement
  
  show: boolean
  handleClose: () => void
}

const modalsRoot = document.getElementById('root-modals') || document.body

const AnnouncementViewModalForm: FC<Props> = ({announcement, isAnnouncementLoading, show, handleClose}) => {
  const {setItemIdForSecondOperation} = useListView()
  const {refetch} = useQueryResponse()
  const intl = useIntl()
  const {currentUser, auth} = useAuth()

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    if(setItemIdForSecondOperation)
      setItemIdForSecondOperation(undefined)
  }

  return(
    <div id='kt_modal_add_expense_form'>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_expense_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_expense_header'
          data-kt-scroll-wrappers='#kt_modal_add_expense_scroll'
          data-kt-scroll-offset='300px'
        >
        
        {/*begin::Row*/}
									<div className="row g-5 g-xl-10 mb-1 mb-xl-1">
										{/*begin::Col*/}
										<div className="col-xxl-6">
											{/*begin::Card widget 18*/}
											<div className="card card-flush h-xl-100">
												{/*begin::Body*/}
												<div className="card-body py-2">
													{/*begin::Row*/}
													<div className="row gx-9 h-100">
														{/*begin::Col*/}
														{announcement.imageId ? 
														<div className="col-sm-12 mb-10 mb-sm-0">
															{/*begin::Image*/}
															<div className="bgi-no-repeat bgi-position-center bgi-size-cover card-rounded min-h-400px min-h-sm-100 h-100">
															<ImageWidget id={announcement.imageId} width='400px'/>
															</div>
															{/*end::Image*/}
														</div>
														: 
														<></>
														}
														{/*end::Col*/}
														{/*begin::Col*/}
														<div className="col-sm-12">
															{/*begin::Wrapper*/}
															<div className="d-flex flex-column h-100">
																{/*begin::Header*/}
																<div className="mt-7">
																	{/*begin::Headin*/}
																	<div className="d-flex flex-stack mb-6">
																		{/*begin::Title*/}
																		<div className="flex-shrink-0 me-5">
																			<span className="text-gray-800 fs-1 fw-bold">{announcement.title}</span>
																		</div>
																		{/*end::Title*/}
																		
																	</div>
																	{/*end::Heading*/}
																</div>
																{/*end::Header*/}
																{/*begin::Body*/}
																<div className="mb-6">
																	{/*begin::Text*/}
																	<span className="fw-semibold text-gray-600 fs-6 mb-8 d-block">{announcement.content}</span>
																	{/*end::Text*/}
																	{/*begin::Stats*/}
																	<div className="d-flex">
																		{/*begin::Stat*/}
																		<div className="border border-gray-300 border-dashed rounded min-w-100px w-100 py-2 px-4 me-6 mb-3">
																			{/*begin::Date*/}
																			<span className="fs-6 text-gray-700 fw-bold">{announcement.startDate !== undefined ? new Date(announcement.startDate).toLocaleDateString() : ''}</span>
																			{/*end::Date*/}
																			{/*begin::Label*/}
																			<div className="fw-semibold text-gray-400">{intl.formatMessage({id: 'd3c'})}</div>
																			{/*end::Label*/}
																		</div>
																		{/*end::Stat*/}
																		{/*begin::Stat*/}
																		<div className="border border-gray-300 border-dashed rounded min-w-100px w-100 py-2 px-4 mb-3">
																			{/*begin::Number*/}
																			<span className="fs-6 text-gray-700 fw-bold">{announcement.endDate !== undefined ? new Date(announcement.endDate).toLocaleDateString() : ''}</span>
																			{/*end::Number*/}
																			{/*begin::Label*/}
																			<div className="fw-semibold text-gray-400">{intl.formatMessage({id: '5bi'})}</div>
																			{/*end::Label*/}
																		</div>
																		{/*end::Stat*/}
																	</div>
																	{/*end::Stats*/}
																</div>
																{/*end::Body*/}
															</div>
															{/*end::Wrapper*/}
														</div>
														{/*end::Col*/}
													</div>
													{/*end::Row*/}
												</div>
												{/*end::Body*/}
											</div>
											{/*end::Card widget 18*/}
										</div>
										{/*end::Col*/}
									</div>
									{/*end::Row*/}
        
        
        </div>
        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-expenses-modal-action='cancel'
          >
            {intl.formatMessage({id: 'bmg'})}
          </button>
        </div>
        {/* end::Actions */}
      </div>
  )
}


export {AnnouncementViewModalForm}

