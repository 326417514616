import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'
import {Country, CountriesQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const GET_COUNTRY_URL = `${API_URL}/country/getcountry`
const CREATE_COUNTRY_URL = `${API_URL}/country/addcountry`
const UPDATE_COUNTRY_URL = `${API_URL}/country/updatecountry`
const DELETE_COUNTRY_URL = `${API_URL}/country/deletecountry`
const GET_COUNTRIES_URL = `${API_URL}/country/getcountries`

const getCountries = (query: string): Promise<CountriesQueryResponse> => {
  return axios
    .get(`${GET_COUNTRIES_URL}?${query}`)
    .then((d: AxiosResponse<CountriesQueryResponse>) => d.data)
}

const getCountryById = (id: ID): Promise<Country | undefined> => {
  return axios
    .get(`${GET_COUNTRY_URL}/${id}`)
    .then((response: AxiosResponse<Response<Country>>) => response.data)
    .then((response: Response<Country>) => response.data)
}

const createCountry = (country: Country): Promise<Country | undefined> => {
  return axios
    .post(CREATE_COUNTRY_URL, country)
    .then((response: AxiosResponse<Response<Country>>) => response.data)
    .then((response: Response<Country>) => response.data)
}

const updateCountry = (country: Country): Promise<Country | undefined> => {
  return axios
    .put(`${UPDATE_COUNTRY_URL}/${country.id}`, country)
    .then((response: AxiosResponse<Response<Country>>) => response.data)
    .then((response: Response<Country>) => response.data)
}

const deleteCountry = (countryId: ID): Promise<void> => {
  return axios.delete(`${DELETE_COUNTRY_URL}/${countryId}`).then(() => {})
}

const deleteSelectedCountries = (countryIds: Array<ID>): Promise<void> => {
  const requests = countryIds.map((id) => axios.delete(`${DELETE_COUNTRY_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {getCountries, deleteCountry, deleteSelectedCountries, getCountryById, createCountry, updateCountry}
