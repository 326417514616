import React, {FC, createContext, useContext} from 'react'
import {WithChildren, isNotEmpty} from '../../../helpers'

const TERM_CONFIG_KEY = process.env.REACT_APP_TERM_CONFIG_KEY || 'OpraziTermConfig'
const TERM_NAME_CONFIG_KEY = process.env.REACT_APP_TERM_CONFIG_KEY || 'OpraziTermNameConfig'

const initialState = {
  selectedTerm: 0 ,
  selectedTermName: ''
}

function getConfig() {
  const term = localStorage.getItem(TERM_CONFIG_KEY)
  if (isNotEmpty(term)) {
    try {
      return JSON.parse(String(term)).selectedTerm
    } catch (er) {
      console.error(er)
    }
  }
  return 0
}

function getTermNameConfig() {
  const term = localStorage.getItem(TERM_NAME_CONFIG_KEY)
  if (isNotEmpty(term)) {
    try {
      return JSON.parse(String(term)).selectedTermName
    } catch (er) {
      console.error(er)
    }
  }
  return ''
}

// Side effect
export function setTerm(term: string) {
  localStorage.setItem(TERM_CONFIG_KEY, JSON.stringify({selectedTerm: term}))
}

export function setTermName(termName: string) {
  localStorage.setItem(TERM_NAME_CONFIG_KEY, JSON.stringify({selectedTermName: termName}))
}

const TermContext = createContext(initialState)

const useTerm = () => {
  return useContext(TermContext).selectedTerm
}

const useTermName = () => {
  return useContext(TermContext).selectedTermName
}

const TermsProvider: FC<WithChildren> = ({children}) => {
  const term = getConfig()
  const termName = getTermNameConfig()
  return <TermContext.Provider value={{selectedTerm : term, selectedTermName : termName}}>{children}</TermContext.Provider>
}

export {TermsProvider, useTerm, useTermName}
