import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'
import {ChartModel} from '../../../../../_metronic/partials/widgets/charts/_models'
import {Exam, ExamFile, ExamsQueryResponse, ExamResultView, ExamResultViewsQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const GET_EXAM_URL = `${API_URL}/exam/getexam`
const GET_EXAM_COUNT_CHART_URL = `${API_URL}/exam/getexamcountchart`
const GET_EXAM_LESSON_COUNT_CHART_URL = `${API_URL}/exam/getexamlessoncountchart`
const CREATE_EXAM_URL = `${API_URL}/exam/addexam`
const UPDATE_EXAM_URL = `${API_URL}/exam/updateexam`
const READ_EXAM_URL = `${API_URL}/exam/readexam`
const DELETE_EXAM_URL = `${API_URL}/exam/deleteexam`
const GET_EXAMS_URL = `${API_URL}/exam/getexams`
const GET_CUSTOMER_EXAMS_URL = `${API_URL}/exam/getcustomerexams`
const GET_EXAMRESULTVIEW_URL = `${API_URL}/exam/getexamresultview`

const getExams = (query: string): Promise<ExamsQueryResponse> => {
  return axios
    .get(`${GET_EXAMS_URL}?${query}`)
    .then((d: AxiosResponse<ExamsQueryResponse>) => d.data)
}

const getCustomerExams = (query: string): Promise<ExamsQueryResponse> => {
  return axios
    .get(`${GET_CUSTOMER_EXAMS_URL}?${query}`)
    .then((d: AxiosResponse<ExamsQueryResponse>) => d.data)
}

const getExamResultViews= (query: string): Promise<ExamResultViewsQueryResponse> => {
  return axios
    .get(`${GET_EXAMRESULTVIEW_URL}?${query}`)
    .then((d: AxiosResponse<ExamResultViewsQueryResponse>) => d.data)
}

const getExamById = (id: ID): Promise<Exam | undefined> => {
  return axios
    .get(`${GET_EXAM_URL}/${id}`)
    .then((response: AxiosResponse<Response<Exam>>) => response.data)
    .then((response: Response<Exam>) => response.data)
}

const getExamCountChart = (query: string): Promise<ChartModel | undefined> => {
  return axios
    .get(`${GET_EXAM_COUNT_CHART_URL}?${query}`)
    .then((response: AxiosResponse<Response<ChartModel>>) => response.data)
    .then((response: Response<ChartModel>) => response.data)
}

const getExamLessonCountChart = (query: string): Promise<ChartModel | undefined> => {
  return axios
    .get(`${GET_EXAM_LESSON_COUNT_CHART_URL}?${query}`)
    .then((response: AxiosResponse<Response<ChartModel>>) => response.data)
    .then((response: Response<ChartModel>) => response.data)
}

const createExam = (exam: Exam): Promise<Exam | undefined> => {
  return axios
    .post(CREATE_EXAM_URL, exam)
    .then((response: AxiosResponse<Response<Exam>>) => response.data)
    .then((response: Response<Exam>) => response.data)
}

const updateExam = (exam: Exam): Promise<Exam | undefined> => {
  return axios
    .put(`${UPDATE_EXAM_URL}/${exam.id}`, exam)
    .then((response: AxiosResponse<Response<Exam>>) => response.data)
    .then((response: Response<Exam>) => response.data)
}

const readExam = (id: ID): Promise<Exam | undefined> => {
  return axios
    .put(`${READ_EXAM_URL}/${id}`)
    .then((response: AxiosResponse<Response<Exam>>) => response.data)
    .then((response: Response<Exam>) => response.data)
}

const deleteExam = (examId: ID): Promise<void> => {
  return axios.delete(`${DELETE_EXAM_URL}/${examId}`).then(() => {})
}

const deleteSelectedExams = (examIds: Array<ID>): Promise<void> => {
  const requests = examIds.map((id) => axios.delete(`${DELETE_EXAM_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {getExams, getExamResultViews, getCustomerExams, deleteExam, getExamCountChart, getExamLessonCountChart, deleteSelectedExams, getExamById, createExam, updateExam, readExam}
