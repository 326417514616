import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'
import {ChartModel} from '../../../../../_metronic/partials/widgets/charts/_models'
import {Menu, MenusQueryResponse, MenusCalendarQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const GET_MENU_URL = `${API_URL}/menu/getmenu`
const GET_MENU_COUNT_CHART_URL = `${API_URL}/menu/getmenucountchart`
const GET_MENU_LESSON_COUNT_CHART_URL = `${API_URL}/menu/getmenulessoncountchart`
const CREATE_MENU_URL = `${API_URL}/menu/addmenu`
const UPDATE_MENU_URL = `${API_URL}/menu/updatemenu`
const DELETE_MENU_URL = `${API_URL}/menu/deletemenu`
const GET_MENUS_URL = `${API_URL}/menu/getmenus`
const GET_CUSTOMER_MENUS_URL = `${API_URL}/menu/getmenus`
const GET_MENUS_CALENDAR_URL = `${API_URL}/menu/getmenuscalendar`

const getMenus = (query: string): Promise<MenusQueryResponse> => {
  return axios
    .get(`${GET_MENUS_URL}?${query}`)
    .then((d: AxiosResponse<MenusQueryResponse>) => d.data)
}

const getCustomerMenus = (query: string): Promise<MenusQueryResponse> => {
  return axios
    .get(`${GET_CUSTOMER_MENUS_URL}?${query}`)
    .then((d: AxiosResponse<MenusQueryResponse>) => d.data)
}

const getMenusCalendar = (query: string): Promise<MenusCalendarQueryResponse> => {
  return axios
    .get(`${GET_MENUS_CALENDAR_URL}?${query}`)
    .then((d: AxiosResponse<MenusCalendarQueryResponse>) => d.data)
}

const getMenuById = (id: ID): Promise<Menu | undefined> => {
  return axios
    .get(`${GET_MENU_URL}/${id}`)
    .then((response: AxiosResponse<Response<Menu>>) => response.data)
    .then((response: Response<Menu>) => response.data)
}

const getMenuCountChart = (query: string): Promise<ChartModel | undefined> => {
  return axios
    .get(`${GET_MENU_COUNT_CHART_URL}?${query}`)
    .then((response: AxiosResponse<Response<ChartModel>>) => response.data)
    .then((response: Response<ChartModel>) => response.data)
}

const getMenuLessonCountChart = (query: string): Promise<ChartModel | undefined> => {
  return axios
    .get(`${GET_MENU_LESSON_COUNT_CHART_URL}?${query}`)
    .then((response: AxiosResponse<Response<ChartModel>>) => response.data)
    .then((response: Response<ChartModel>) => response.data)
}


const createMenu = (menu: Menu): Promise<Menu | undefined> => {
  return axios
    .post(CREATE_MENU_URL, menu)
    .then((response: AxiosResponse<Response<Menu>>) => response.data)
    .then((response: Response<Menu>) => response.data)
}

const updateMenu = (menu: Menu): Promise<Menu | undefined> => {
  return axios
    .put(`${UPDATE_MENU_URL}/${menu.id}`, menu)
    .then((response: AxiosResponse<Response<Menu>>) => response.data)
    .then((response: Response<Menu>) => response.data)
}

const deleteMenu = (menuId: ID): Promise<void> => {
  return axios.delete(`${DELETE_MENU_URL}/${menuId}`).then(() => {})
}

const deleteSelectedMenus = (menuIds: Array<ID>): Promise<void> => {
  const requests = menuIds.map((id) => axios.delete(`${DELETE_MENU_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {getMenus, getCustomerMenus, getMenusCalendar, deleteMenu, getMenuCountChart, getMenuLessonCountChart, deleteSelectedMenus, getMenuById, createMenu, updateMenu}
