import {useEffect} from 'react'
import {Outlet} from 'react-router-dom'
import {AsideDefault} from './components/aside/AsideDefault'
import {Footer} from './components/Footer'
import {HeaderWrapper} from './components/header/HeaderWrapper'
import {ScrollTop} from './components/ScrollTop'
import {Content} from './components/Content'
import {PageDataProvider} from './core'
import {useLocation} from 'react-router-dom'
import { DrawerMessenger, ThemeModeProvider } from '../partials'
import {MenuComponent} from '../assets/ts/components'
import {useAuth} from '../../app/modules/auth'
import {ClaimModel} from '../../app/modules/auth/core/_models'
import {YearsProvider} from '../partials/layout/header-menus/YearsManager'
import {TermsProvider} from '../partials/layout/header-menus/TermsManager'

const MasterLayout = () => {
  const {currentUser, auth} = useAuth()
  //document.body.classList.remove('aside-enabled')

  /*if(auth?.currentRole===RoleModel.Customer ||
    auth?.currentRole===RoleModel.Person){
    const element = document.getElementById('kt_aside');
    element?.remove();
  }*/

  const location = useLocation()
  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [location.key])

  return (
    <PageDataProvider>
      <ThemeModeProvider>
        <YearsProvider>
          <TermsProvider>
              <div className='page d-flex flex-row flex-column-fluid'>
                <AsideDefault />
                <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
                  <HeaderWrapper />

                  <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
                    <div className='post d-flex flex-column-fluid' id='kt_post'>
                      <Content>
                        <Outlet />
                      </Content>
                    </div>
                  </div>
                  <Footer />
                </div>
              </div>

              {/* begin:: Drawers */}
              {(currentUser?.menus?.includes(ClaimModel.chat)) && 
                <DrawerMessenger />
              }
              {/* end:: Drawers */}

            <ScrollTop />
          </TermsProvider>
        </YearsProvider>
      </ThemeModeProvider>
    </PageDataProvider>
  )
}

export {MasterLayout}
