/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react'
import { ID, KTSVG } from '../../../../helpers'
import { useAuth } from '../../../../../app/modules/auth'
import { useListView } from '../../../../../app/modules/announcement/announcements-list/core/ListViewProvider'
import { useIntl } from "react-intl"
import { getSubinstitutionCount } from '../../../../../app/modules/subinstitution/subinstitutions-list/core/_requests'
import { getCustomerCount } from '../../../../../app/modules/customer/customers-list/core/_requests'
import { getEmployeeCount } from '../../../../../app/modules/employee/employees-list/core/_requests'
import { RoleModel } from '../../../../../app/modules/auth/core/_models'

type Props = {
	className: string
}

const InstitutionLineStatisticWidget: React.FC<Props> = ({ className }) => {
	const { setItemIdForUpdate } = useListView()
	const intl = useIntl();
	const { currentUser, auth } = useAuth()
	const [subinstitutionCount, setSubinstitutionCount] = useState<number | undefined>(0)
	const [customerCount, setCustomerCount] = useState<number | undefined>(0)
	const [employeeCount, setEmployeeCount] = useState<number | undefined>(0)

	useEffect(() => {
		if (auth?.currentRole === RoleModel.InstitutionManager) {
			getSubinstitutionCount(currentUser?.institutionId).then((value) => { setSubinstitutionCount(value) })
			getCustomerCount(currentUser?.institutionId).then((value) => { setCustomerCount(value) })
			getEmployeeCount(currentUser?.institutionId).then((value) => { setEmployeeCount(value) })
		}
		else if (auth?.currentRole === RoleModel.SchoolManager) {
			getCustomerCount(currentUser?.institutionId).then((value) => { setCustomerCount(value) })
			getEmployeeCount(currentUser?.institutionId).then((value) => { setEmployeeCount(value) })
		}
	}, [])

	const openEditModal = (id: ID) => {
		setItemIdForUpdate(id)
	}

	return (
		<>
			{/*begin::Engage widget 8*/}
			<div className={`card ${className}`} data-bs-theme="light" style={{ background: "linear-gradient(112.14deg, #e02677 0%, #90268c 100%)" }}>
				{/*begin::Body*/}
				<div className="card-body">
					{/*begin::Row*/}
					<div className="row align-items-center h-100">
						{/*begin::Col*/}
						<div className="col-12 ps-xl-13">
							{/*begin::Title*/}
							<div className="text-white mb-6 pt-6">
								<span className="fs-2qx fw-bold">{intl.formatMessage({ id: 'gki' })}</span>
							</div>
							{/*end::Title*/}
							{/*begin::Text*/}
							<span className="fw-semibold text-white fs-6 mb-8 d-block opacity-75">{intl.formatMessage({ id: 'gka' })}</span>
							{/*end::Text*/}
							{/*begin::Items*/}
							<div className="d-flex align-items-center flex-wrap d-grid gap-3 mb-10 mb-xl-20">
								{/*begin::Item*/}
								{auth?.currentRole === RoleModel.InstitutionManager &&
									<div className="d-flex align-items-center me-5 me-xl-13">
										{/*begin::Symbol*/}
										<div className="symbol symbol-30px symbol-circle me-3">
											<span className="symbol-label" style={{ background: "#f53388" }}>
												<i className="ki-duotone ki-abstract-41 fs-5 text-white">
													<span className="path1"></span>
													<span className="path2"></span>
												</i>
											</span>
										</div>
										{/*end::Symbol*/}
										{/*begin::Info*/}
										<div className="text-white">
											<span className="fw-semibold d-block fs-8 opacity-75">{intl.formatMessage({ id: 'wqr' })}</span>
											<span className="fw-bold fs-7">{subinstitutionCount}</span>
										</div>
										{/*end::Info*/}
									</div>
								}
								{/*end::Item*/}
								{/*begin::Item*/}
								<div className="d-flex align-items-center me-5 me-xl-13">
									{/*begin::Symbol*/}
									<div className="symbol symbol-30px symbol-circle me-3">
										<span className="symbol-label" style={{ background: "#f53388" }}>
											<i className="ki-duotone ki-abstract-26 fs-5 text-white">
												<span className="path1"></span>
												<span className="path2"></span>
											</i>
										</span>
									</div>
									{/*end::Symbol*/}
									{/*begin::Info*/}
									<div className="text-white">
										<span className="fw-semibold opacity-75 d-block fs-8">{intl.formatMessage({ id: '4ny' })}</span>
										<span className="fw-bold fs-7">{employeeCount}</span>
									</div>
									{/*end::Info*/}
								</div>
								{/*end::Item*/}
								{/*begin::Item*/}
								<div className="d-flex align-items-center me-5 me-xl-13">
									{/*begin::Symbol*/}
									<div className="symbol symbol-30px symbol-circle me-3">
										<span className="symbol-label" style={{ background: "#f53388" }}>
											<i className="ki-duotone ki-abstract-41 fs-5 text-white">
												<span className="path1"></span>
												<span className="path2"></span>
											</i>
										</span>
									</div>
									{/*end::Symbol*/}
									{/*begin::Info*/}
									<div className="text-white">
										<span className="fw-semibold d-block fs-8 opacity-75">{intl.formatMessage({ id: 'vxt' })}</span>
										<span className="fw-bold fs-7">{customerCount}</span>
									</div>
									{/*end::Info*/}
								</div>
								{/*end::Item*/}

							</div>
							{/*end::Items*/}
						</div>
						{/*end::Col*/}
					</div>
					{/*end::Row*/}
				</div>
				{/*end::Body*/}
			</div>
			{/*end::Engage widget 8*/}
		</>
	)
}

export { InstitutionLineStatisticWidget }
