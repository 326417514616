import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'
import {ChartModel} from '../../../../../_metronic/partials/widgets/charts/_models'
import {Survey, SurveysQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const GET_SURVEY_URL = `${API_URL}/survey/getsurvey`
const GET_USER_SURVEY_URL = `${API_URL}/survey/getusersurvey`
const GET_SURVEY_COUNT_CHART_URL = `${API_URL}/survey/getsurveycountchart`
const GET_SURVEY_LESSON_COUNT_CHART_URL = `${API_URL}/survey/getsurveylessoncountchart`
const CREATE_SURVEY_URL = `${API_URL}/survey/addsurvey`
const UPDATE_SURVEY_URL = `${API_URL}/survey/updatesurvey`
const DELETE_SURVEY_URL = `${API_URL}/survey/deletesurvey`
const GET_SURVEYS_URL = `${API_URL}/survey/getsurveys`
const GET_CUSTOMER_SURVEYS_URL = `${API_URL}/survey/getcustomersurveys`

const getSurveys = (query: string): Promise<SurveysQueryResponse> => {
  return axios
    .get(`${GET_SURVEYS_URL}?${query}`)
    .then((d: AxiosResponse<SurveysQueryResponse>) => d.data)
}

const getCustomerSurveys = (query: string): Promise<SurveysQueryResponse> => {
  return axios
    .get(`${GET_CUSTOMER_SURVEYS_URL}?${query}`)
    .then((d: AxiosResponse<SurveysQueryResponse>) => d.data)
}

const getSurveyById = (id: ID): Promise<Survey | undefined> => {
  return axios
    .get(`${GET_SURVEY_URL}/${id}`)
    .then((response: AxiosResponse<Response<Survey>>) => response.data)
    .then((response: Response<Survey>) => response.data)
}
const getUserSurveyById = (surveyId: ID, userId: ID): Promise<Survey | undefined> => {
  return axios
    .get(`${GET_USER_SURVEY_URL}/${surveyId}/${userId}`)
    .then((response: AxiosResponse<Response<Survey>>) => response.data)
    .then((response: Response<Survey>) => response.data)
}
const getSurveyCountChart = (query: string): Promise<ChartModel | undefined> => {
  return axios
    .get(`${GET_SURVEY_COUNT_CHART_URL}?${query}`)
    .then((response: AxiosResponse<Response<ChartModel>>) => response.data)
    .then((response: Response<ChartModel>) => response.data)
}

const getSurveyLessonCountChart = (query: string): Promise<ChartModel | undefined> => {
  return axios
    .get(`${GET_SURVEY_LESSON_COUNT_CHART_URL}?${query}`)
    .then((response: AxiosResponse<Response<ChartModel>>) => response.data)
    .then((response: Response<ChartModel>) => response.data)
}

const createSurvey = (survey: Survey): Promise<Survey | undefined> => {
  return axios
    .post(CREATE_SURVEY_URL, survey)
    .then((response: AxiosResponse<Response<Survey>>) => response.data)
    .then((response: Response<Survey>) => response.data)
}

const updateSurvey = (survey: Survey): Promise<Survey | undefined> => {
  return axios
    .put(`${UPDATE_SURVEY_URL}/${survey.id}`, survey)
    .then((response: AxiosResponse<Response<Survey>>) => response.data)
    .then((response: Response<Survey>) => response.data)
}

const deleteSurvey = (surveyId: ID): Promise<void> => {
  return axios.delete(`${DELETE_SURVEY_URL}/${surveyId}`).then(() => {})
}

const deleteSelectedSurveys = (surveyIds: Array<ID>): Promise<void> => {
  const requests = surveyIds.map((id) => axios.delete(`${DELETE_SURVEY_URL}/${id}`))
  return axios.all(requests).then(() => {})
}



export {getSurveys, getCustomerSurveys, deleteSurvey, getSurveyCountChart, getSurveyLessonCountChart, deleteSelectedSurveys, getSurveyById, getUserSurveyById, createSurvey, updateSurvey}
