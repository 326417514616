import {ID, Response} from '../../../../../_metronic/helpers'
export type Country = {
    id?: ID
    
    iso?: string
    name?: string
    niceName?: string
    iso3?: string
    numCode?: number
    phoneCode?: number
}

export type CountriesQueryResponse = Response<Array<Country>>

export const initialCountry: Country = {
    
    iso: '',
    name: '',
    niceName: '',
    iso3: '',
    numCode: 0,
    phoneCode: 0
}

export const initialCountries: Country[] = [{
    
    iso: '',
    name: '',
    niceName: '',
    iso3: '',
    numCode: 0,
    phoneCode: 0
}]
