import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'
import {ChartModel} from '../../../../../_metronic/partials/widgets/charts/_models'
import {Study, StudiesQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const GET_STUDY_URL = `${API_URL}/study/getstudy`
const GET_STUDY_COUNT_CHART_URL = `${API_URL}/study/getstudycountchart`
const GET_STUDY_LESSON_COUNT_CHART_URL = `${API_URL}/study/getstudylessoncountchart`
const CREATE_STUDY_URL = `${API_URL}/study/addstudy`
const UPDATE_STUDY_URL = `${API_URL}/study/updatestudy`
const DELETE_STUDY_URL = `${API_URL}/study/deletestudy`
const GET_STUDIES_URL = `${API_URL}/study/getstudies`
const GET_CUSTOMER_STUDIES_URL = `${API_URL}/study/getcustomerstudies`
const GET_CUSTOMER_STUDY_COUNT_URL = `${API_URL}/study/getcustomerstudycount`

const getStudies = (query: string): Promise<StudiesQueryResponse> => {
  return axios
    .get(`${GET_STUDIES_URL}?${query}`)
    .then((d: AxiosResponse<StudiesQueryResponse>) => d.data)
}

const getCustomerStudies = (query: string): Promise<StudiesQueryResponse> => {
  return axios
    .get(`${GET_CUSTOMER_STUDIES_URL}?${query}`)
    .then((d: AxiosResponse<StudiesQueryResponse>) => d.data)
}

const getStudyById = (id: ID): Promise<Study | undefined> => {
  return axios
    .get(`${GET_STUDY_URL}/${id}`)
    .then((response: AxiosResponse<Response<Study>>) => response.data)
    .then((response: Response<Study>) => response.data)
}

const getCustomerStudyCount = (query: string): Promise<number | undefined> => {
  return axios
    .get(`${GET_CUSTOMER_STUDY_COUNT_URL}?${query}`)
    .then((response: AxiosResponse<Response<number>>) => response.data)
    .then((response: Response<number>) => response.data)
}

const getStudyCountChart = (query: string): Promise<ChartModel | undefined> => {
  return axios
    .get(`${GET_STUDY_COUNT_CHART_URL}?${query}`)
    .then((response: AxiosResponse<Response<ChartModel>>) => response.data)
    .then((response: Response<ChartModel>) => response.data)
}

const getStudyLessonCountChart = (query: string): Promise<ChartModel | undefined> => {
  return axios
    .get(`${GET_STUDY_LESSON_COUNT_CHART_URL}?${query}`)
    .then((response: AxiosResponse<Response<ChartModel>>) => response.data)
    .then((response: Response<ChartModel>) => response.data)
}

const createStudy = (study: Study): Promise<Study | undefined> => {
  return axios
    .post(CREATE_STUDY_URL, study)
    .then((response: AxiosResponse<Response<Study>>) => response.data)
    .then((response: Response<Study>) => response.data)
}

const updateStudy = (study: Study): Promise<Study | undefined> => {
  return axios
    .put(`${UPDATE_STUDY_URL}/${study.id}`, study)
    .then((response: AxiosResponse<Response<Study>>) => response.data)
    .then((response: Response<Study>) => response.data)
}

const deleteStudy = (studyId: ID): Promise<void> => {
  return axios.delete(`${DELETE_STUDY_URL}/${studyId}`).then(() => {})
}

const deleteSelectedStudies = (studyIds: Array<ID>): Promise<void> => {
  const requests = studyIds.map((id) => axios.delete(`${DELETE_STUDY_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

let eventGuid = 0
const createEventId = () => {
  return String(eventGuid++)
}

export {getStudies, getCustomerStudies, getCustomerStudyCount, deleteStudy, deleteSelectedStudies, getStudyById, getStudyCountChart, getStudyLessonCountChart, createStudy, updateStudy, createEventId}
