import {ID, Response} from '../../../../../_metronic/helpers'
export type Term = {
    id?: ID
    
    subinstitutionId?: number
    name?: string
    startDate?: Date
    endDate?: Date
    institutionId?: number
    academicYearId?: number
}

export type TermsQueryResponse = Response<Array<Term>>

export const initialTerm: Term = {
    
    subinstitutionId: 0,
    name: '',
    startDate: undefined,
    endDate: undefined,
    institutionId: 0,
    academicYearId: 0
}

export const initialTerms: Term[] = [{
    
    subinstitutionId: 0,
    name: '',
    startDate: new Date(),
    endDate: new Date(),
    institutionId: 0,
    academicYearId: 0
}]
