/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import { useIntl } from 'react-intl'
import { KTSVG } from '../../../helpers'
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub'
import { AsideMenuItem } from './AsideMenuItem'
import { useAuth } from '../../../../app/modules/auth'
import { ClaimModel } from '../../../../app/modules/auth/core/_models'


export function AsideMenuMain() {
  const intl = useIntl()
  const { currentUser } = useAuth()

  return (
    <>
      {(currentUser?.menus?.includes(ClaimModel.dashboard)) &&
        <>
          <AsideMenuItem
            to='/dashboard'
            title={intl.formatMessage({ id: 'dtq' })}
            icon='/media/icons/duotune/art/dashboard1.svg'
            fontIcon='bi-app-indicator'
          />
          <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1'>{intl.formatMessage({ id: 'usw' })}</span>
            </div>
          </div>
        </>
      }

      {(currentUser?.menus?.includes(ClaimModel.course) ||
        currentUser?.menus?.includes(ClaimModel.coursetype) ||
        currentUser?.menus?.includes(ClaimModel.courselevel) ||
        currentUser?.menus?.includes(ClaimModel.courseskill) ||
        currentUser?.menus?.includes(ClaimModel.courseresulttype) ||
        currentUser?.menus?.includes(ClaimModel.courseresult) ||
        currentUser?.menus?.includes(ClaimModel.proficiencytest) ||
        currentUser?.menus?.includes(ClaimModel.publishingoption) ||
        currentUser?.menus?.includes(ClaimModel.courseschedule)) &&
        <AsideMenuItemWithSub
          to='/crafted/courses'
          title={intl.formatMessage({ id: 'o9a' })}
          fontIcon='bi-chat-left'
          icon='/media/icons/duotune/communication/chat1.svg'
        >
          {currentUser?.menus?.includes(ClaimModel.course) && <AsideMenuItem to='/crafted/courses/course/course' title={intl.formatMessage({ id: 'o8a' })} hasBullet={true} /> }
          {currentUser?.menus?.includes(ClaimModel.courseresult) && <AsideMenuItem to='/crafted/courses/courseresult/courseresult' title={intl.formatMessage({ id: 'o8g' })} hasBullet={true} /> }
          {currentUser?.menus?.includes(ClaimModel.courseresult) && <AsideMenuItem to='/crafted/courses/coursefinished/coursefinished' title={intl.formatMessage({ id: 'o8s' })} hasBullet={true} /> }
          {currentUser?.menus?.includes(ClaimModel.coursetype) && <AsideMenuItem to='/crafted/courses/coursetype/coursetype' title={intl.formatMessage({ id: 'o8b' })} hasBullet={true} /> }
          {currentUser?.menus?.includes(ClaimModel.courselevel) && <AsideMenuItem to='/crafted/courses/courselevel/courselevel' title={intl.formatMessage({ id: 'o8c' })} hasBullet={true} /> }
          {currentUser?.menus?.includes(ClaimModel.courseskill) && <AsideMenuItem to='/crafted/courses/courseskill/courseskill' title={intl.formatMessage({ id: 'o8h' })} hasBullet={true} /> }
          {currentUser?.menus?.includes(ClaimModel.courseresulttype) && <AsideMenuItem to='/crafted/courses/courseresulttype/courseresulttype' title={intl.formatMessage({ id: 'o8i' })} hasBullet={true} /> }
          {currentUser?.menus?.includes(ClaimModel.proficiencytest) && <AsideMenuItem to='/crafted/courses/proficiencytest/proficiencytest' title={intl.formatMessage({ id: 'o8d' })} hasBullet={true} /> }
          {currentUser?.menus?.includes(ClaimModel.courseschedule) && <AsideMenuItem to='/crafted/courses/courseschedule/courseschedule' title={intl.formatMessage({ id: 'o9p' })} hasBullet={true} /> }
          {currentUser?.menus?.includes(ClaimModel.publishingoption) && <AsideMenuItem to='/crafted/courses/publishingoption/publishingoption' title={intl.formatMessage({ id: 'o8u' })} hasBullet={true} /> }
        </AsideMenuItemWithSub>
      }

      {(currentUser?.menus?.includes(ClaimModel.chat)) &&
        <>
          <AsideMenuItemWithSub
            to='/apps/chat'
            title={intl.formatMessage({ id: '0x6' })}
            fontIcon='bi-chat-left'
            icon='/media/icons/duotune/communication/chat1.svg'
          >
            <AsideMenuItem to='/apps/chat/private-chat' title={intl.formatMessage({ id: 'a38' })} hasBullet={true} />
          </AsideMenuItemWithSub>

          <div className='menu-item'>
            <div className='menu-content'>
              <div className='separator mx-1 my-4'></div>
            </div>
          </div>
        </>
      }

      {(currentUser?.menus?.includes(ClaimModel.file) ||
        currentUser?.menus?.includes(ClaimModel.filetype) ||
        currentUser?.menus?.includes(ClaimModel.fileshare)) &&
        <AsideMenuItemWithSub
          to='/crafted/accounts'
          title={intl.formatMessage({ id: 'd1b' })}
          icon='/media/icons/duotune/communication/account1.svg'
          fontIcon='bi-person'
        >
          <AsideMenuItem to='/crafted/accounts/profile/overview' title={intl.formatMessage({ id: 'dud' })} hasBullet={true} />
          {currentUser?.menus?.includes(ClaimModel.file) && <AsideMenuItem to='/crafted/accounts/file/file' title={intl.formatMessage({ id: '5qi' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.filetype) && <AsideMenuItem to='/crafted/accounts/filetype/filetype' title={intl.formatMessage({ id: 'hzh' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.fileshare) && <AsideMenuItem to='/crafted/accounts/fileshare/fileshare' title={intl.formatMessage({ id: '4th' })} hasBullet={true} />}
        </AsideMenuItemWithSub>
      }

      {(currentUser?.menus?.includes(ClaimModel.announcement) ||
        currentUser?.menus?.includes(ClaimModel.customerannouncement) ||
        currentUser?.menus?.includes(ClaimModel.dashboardannouncement)) &&
        <AsideMenuItemWithSub
          to='/crafted/announcements'
          title={intl.formatMessage({ id: 'ow5' })}
          icon='/media/icons/duotune/communication/announcement1.svg'
          fontIcon='bi-person'
        >
          {currentUser?.menus?.includes(ClaimModel.dashboardannouncement) && <AsideMenuItem to='/crafted/announcements/dashboardannouncement/dashboardannouncement' title={intl.formatMessage({ id: 'dtq' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.announcement) && <AsideMenuItem to='/crafted/announcements/announcement/announcement' title={intl.formatMessage({ id: 'ow5' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.customerannouncement) && <AsideMenuItem to='/crafted/announcements/customerannouncement/customerannouncement' title={intl.formatMessage({ id: 'ow5' })} hasBullet={true} />}

        </AsideMenuItemWithSub>
      }

      {(currentUser?.menus?.includes(ClaimModel.user) ||
        currentUser?.menus?.includes(ClaimModel.role) ||
        currentUser?.menus?.includes(ClaimModel.roleclaim) ||
        currentUser?.menus?.includes(ClaimModel.userclaim) ||
        currentUser?.menus?.includes(ClaimModel.userlogin) ||
        currentUser?.menus?.includes(ClaimModel.userrole)) &&
        <AsideMenuItemWithSub
          to='/crafted/users'
          title={intl.formatMessage({ id: 'x34' })}
          icon='/media/icons/duotune/communication/user1.svg'
          fontIcon='bi-person'
        >
          {currentUser?.menus?.includes(ClaimModel.user) && <AsideMenuItem to='/crafted/users/user/user' title={intl.formatMessage({ id: 'kex' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.role) && <AsideMenuItem to='/crafted/users/role/role' title={intl.formatMessage({ id: 'klh' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.roleclaim) && <AsideMenuItem to='/crafted/users/roleclaim/roleclaim' title={intl.formatMessage({ id: '0hc' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.userclaim) && <AsideMenuItem to='/crafted/users/userclaim/userclaim' title={intl.formatMessage({ id: 'f6a' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.userlogin) && <AsideMenuItem to='/crafted/users/userlogin/userlogin' title={intl.formatMessage({ id: 'ods' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.userrole) && <AsideMenuItem to='/crafted/users/userrole/userrole' title={intl.formatMessage({ id: 'hxa' })} hasBullet={true} />}
        </AsideMenuItemWithSub>
      }

      {(currentUser?.menus?.includes(ClaimModel.claimvalue) ||
        currentUser?.menus?.includes(ClaimModel.moduleinstitution) ||
        currentUser?.menus?.includes(ClaimModel.page) ||
        currentUser?.menus?.includes(ClaimModel.module)) &&
        <AsideMenuItemWithSub
          to='/crafted/modules'
          title={intl.formatMessage({ id: 'Modül' })}
          icon='/media/icons/duotune/communication/user1.svg'
          fontIcon='bi-person'
        >
          {currentUser?.menus?.includes(ClaimModel.claimvalue) && <AsideMenuItem to='/crafted/modules/claimvalue/claimvalue' title={intl.formatMessage({ id: 'Yetkiler' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.moduleinstitution) && <AsideMenuItem to='/crafted/modules/moduleinstitution/moduleinstitution' title={intl.formatMessage({ id: 'Kurum Modülleri' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.page) && <AsideMenuItem to='/crafted/modules/page/page' title={intl.formatMessage({ id: 'Sayfalar' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.module) && <AsideMenuItem to='/crafted/modules/module/module' title={intl.formatMessage({ id: 'Modüller' })} hasBullet={true} />}
        </AsideMenuItemWithSub>
      }

      {(currentUser?.menus?.includes(ClaimModel.institution) ||
        currentUser?.menus?.includes(ClaimModel.persontype) ||
        currentUser?.menus?.includes(ClaimModel.gender)) &&
        <AsideMenuItemWithSub
          to='/crafted/institutions'
          title={intl.formatMessage({ id: 'qov' })}
          icon='/media/icons/duotune/communication/institution.svg'
          fontIcon='bi-person'
        >
          {currentUser?.menus?.includes(ClaimModel.institution) && <AsideMenuItem to='/crafted/institutions/institution/institution' title={intl.formatMessage({ id: 'qov' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.persontype) && <AsideMenuItem to='/crafted/institutions/persontype/persontype' title={intl.formatMessage({ id: 'nis' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.gender) && <AsideMenuItem to='/crafted/institutions/gender/gender' title={intl.formatMessage({ id: 'ibl' })} hasBullet={true} />}
        </AsideMenuItemWithSub>
      }

      {(currentUser?.menus?.includes(ClaimModel.country) ||
        currentUser?.menus?.includes(ClaimModel.city) ||
        currentUser?.menus?.includes(ClaimModel.language)) &&
        <AsideMenuItemWithSub
          to='/crafted/countries'
          title={intl.formatMessage({ id: 'o9q' })}
          icon='/media/icons/duotune/communication/country.svg'
          fontIcon='bi-person'
        >
          {currentUser?.menus?.includes(ClaimModel.country) && <AsideMenuItem to='/crafted/countries/country/country' title={intl.formatMessage({ id: 'o9q' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.city) && <AsideMenuItem to='/crafted/countries/city/city' title={intl.formatMessage({ id: 're9' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.language) && <AsideMenuItem to='/crafted/countries/language/language' title={intl.formatMessage({ id: '2qg' })} hasBullet={true} />}
        </AsideMenuItemWithSub>
      }

      {(currentUser?.menus?.includes(ClaimModel.subinstitution) ||
        currentUser?.menus?.includes(ClaimModel.subinstitutiontype) ||
        currentUser?.menus?.includes(ClaimModel.class) ||
        currentUser?.menus?.includes(ClaimModel.subinstitutiontypebranch) ||
        currentUser?.menus?.includes(ClaimModel.academicyear) ||
        currentUser?.menus?.includes(ClaimModel.term) ||
        currentUser?.menus?.includes(ClaimModel.place) ||
        currentUser?.menus?.includes(ClaimModel.lesson) ||
        currentUser?.menus?.includes(ClaimModel.subject)) &&
        <AsideMenuItemWithSub
          to='/crafted/subinstitutions'
          title={intl.formatMessage({ id: 'wqr' })}
          icon='/media/icons/duotune/communication/school1.svg'
          fontIcon='bi-person'
        >
          {currentUser?.menus?.includes(ClaimModel.subinstitution) && <AsideMenuItem to='/crafted/subinstitutions/subinstitution/subinstitution' title={intl.formatMessage({ id: 'wqr' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.subinstitutiontype) && <AsideMenuItem to='/crafted/subinstitutions/subinstitutiontype/subinstitutiontype' title={intl.formatMessage({ id: 'l0p' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.class) && <AsideMenuItem to='/crafted/subinstitutions/class/class' title={intl.formatMessage({ id: 'c76' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.subinstitutiontypebranch) && <AsideMenuItem to='/crafted/subinstitutions/subinstitutiontypebranch/subinstitutiontypebranch' title={intl.formatMessage({ id: '6rk' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.academicyear) && <AsideMenuItem to='/crafted/subinstitutions/academicyear/academicyear' title={intl.formatMessage({ id: '7ir' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.term) && <AsideMenuItem to='/crafted/subinstitutions/term/term' title={intl.formatMessage({ id: 'ahe' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.place) && <AsideMenuItem to='/crafted/subinstitutions/place/place' title={intl.formatMessage({ id: 'fp0' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.lesson) && <AsideMenuItem to='/crafted/subinstitutions/lesson/lesson' title={intl.formatMessage({ id: 'dm0' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.subject) && <AsideMenuItem to='/crafted/subinstitutions/subject/subject' title={intl.formatMessage({ id: 'qer' })} hasBullet={true} />}
        </AsideMenuItemWithSub>
      }


      {(currentUser?.menus?.includes(ClaimModel.study) ||
        currentUser?.menus?.includes(ClaimModel.customerstudy) ||
        currentUser?.menus?.includes(ClaimModel.reportstudy) ||
        currentUser?.menus?.includes(ClaimModel.dashboardstudy) ||
        currentUser?.menus?.includes(ClaimModel.studytype) ||
        currentUser?.menus?.includes(ClaimModel.studyeventtype)) &&
        <AsideMenuItemWithSub
          to='/crafted/studies'
          title={intl.formatMessage({ id: '2uv' })}
          icon='/media/icons/duotune/communication/study1.svg'
          fontIcon='bi-person'
        >
          {currentUser?.menus?.includes(ClaimModel.dashboardstudy) && <AsideMenuItem to='/crafted/studies/dashboardstudy/dashboardstudy' title={intl.formatMessage({ id: 'dtq' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.study) && <AsideMenuItem to='/crafted/studies/study/study' title={intl.formatMessage({ id: '2uv' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.customerstudy) && <AsideMenuItem to='/crafted/studies/customerstudy/customerstudy' title={intl.formatMessage({ id: '2uv' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.reportstudy) && <AsideMenuItem to='/crafted/studies/reportstudy/reportstudy' title={intl.formatMessage({ id: 'jfp' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.studytype) && <AsideMenuItem to='/crafted/studies/studytype/studytype' title={intl.formatMessage({ id: '14z' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.studyeventtype) && <AsideMenuItem to='/crafted/studies/studyeventtype/studyeventtype' title={intl.formatMessage({ id: 'gtw' })} hasBullet={true} />}
        </AsideMenuItemWithSub>
      }

      {(currentUser?.menus?.includes(ClaimModel.assignment) ||
        currentUser?.menus?.includes(ClaimModel.customerassignment) ||
        currentUser?.menus?.includes(ClaimModel.dashboardassignment) ||
        currentUser?.menus?.includes(ClaimModel.reportassignment) ||
        currentUser?.menus?.includes(ClaimModel.assignmentanswer) ||
        currentUser?.menus?.includes(ClaimModel.assignmentgrade)) &&
        <AsideMenuItemWithSub
          to='/crafted/assignments'
          title={intl.formatMessage({ id: '3db' })}
          icon='/media/icons/duotune/communication/assignment1.svg'
          fontIcon='bi-person'
        >
          {currentUser?.menus?.includes(ClaimModel.dashboardassignment) && <AsideMenuItem to='/crafted/assignments/dashboardassignment/dashboardassignment' title={intl.formatMessage({ id: 'dtq' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.assignment) && <AsideMenuItem to='/crafted/assignments/assignment/assignment' title={intl.formatMessage({ id: '3db' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.customerassignment) && <AsideMenuItem to='/crafted/assignments/customerassignment/customerassignment' title={intl.formatMessage({ id: '3db' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.reportassignment) && <AsideMenuItem to='/crafted/assignments/reportassignment/reportassignment' title={intl.formatMessage({ id: 'jfp' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.assignmentanswer) && <AsideMenuItem to='/crafted/assignments/assignmentanswer/assignmentanswer' title={intl.formatMessage({ id: 'hbu' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.assignmentgrade) && <AsideMenuItem to='/crafted/assignments/assignmentgrade/assignmentgrade' title={intl.formatMessage({ id: 'xep' })} hasBullet={true} />}
        </AsideMenuItemWithSub>
      }

      {(currentUser?.menus?.includes(ClaimModel.employee) ||
        currentUser?.menus?.includes(ClaimModel.employeetype) ||
        currentUser?.menus?.includes(ClaimModel.dashboardemployee) ||
        currentUser?.menus?.includes(ClaimModel.workingstatue) ||
        currentUser?.menus?.includes(ClaimModel.unit) ||
        currentUser?.menus?.includes(ClaimModel.unittype)) &&
        <AsideMenuItemWithSub
          to='/crafted/employees'
          title={intl.formatMessage({ id: '4ny' })}
          icon='/media/icons/duotune/communication/employee1.svg'
          fontIcon='bi-person'
        >
          {currentUser?.menus?.includes(ClaimModel.dashboardemployee) && <AsideMenuItem to='/crafted/employees/dashboardemployee/dashboardemployee' title={intl.formatMessage({ id: 'dtq' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.employee) && <AsideMenuItem to='/crafted/employees/employee/employee' title={intl.formatMessage({ id: '4ny' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.employeetype) && <AsideMenuItem to='/crafted/employees/employeetype/employeetype' title={intl.formatMessage({ id: 'uq5' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.workingstatue) && <AsideMenuItem to='/crafted/employees/workingstatue/workingstatue' title={intl.formatMessage({ id: '11u' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.unit) && <AsideMenuItem to='/crafted/employees/unit/unit' title={intl.formatMessage({ id: '5sh' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.unittype) && <AsideMenuItem to='/crafted/employees/unittype/unittype' title={intl.formatMessage({ id: 'gsb' })} hasBullet={true} />}
        </AsideMenuItemWithSub>
      }

      {(currentUser?.menus?.includes(ClaimModel.customer) ||
        currentUser?.menus?.includes(ClaimModel.dashboardcustomer) ||
        currentUser?.menus?.includes(ClaimModel.customerinfo) ||
        currentUser?.menus?.includes(ClaimModel.person)) &&
        <AsideMenuItemWithSub
          to='/crafted/customers'
          title={intl.formatMessage({ id: 'vxt' })}
          icon='/media/icons/duotune/communication/customer1.svg'
          fontIcon='bi-person'
        >
          {currentUser?.menus?.includes(ClaimModel.dashboardcustomer) && <AsideMenuItem to='/crafted/customers/dashboardcustomer/dashboardcustomer' title={intl.formatMessage({ id: 'dtq' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.customerinfo) && <AsideMenuItem to='/crafted/customers/customerinfo/customerinfo' title={intl.formatMessage({ id: 's4l' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.customer) && <AsideMenuItem to='/crafted/customers/customer/customer' title={intl.formatMessage({ id: 'vxt' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.person) && <AsideMenuItem to='/crafted/customers/person/person' title={intl.formatMessage({ id: 'pb0' })} hasBullet={true} />}
        </AsideMenuItemWithSub>
      }

      {(currentUser?.menus?.includes(ClaimModel.club) ||
        currentUser?.menus?.includes(ClaimModel.customerclub) ||
        currentUser?.menus?.includes(ClaimModel.reportclub) ||
        currentUser?.menus?.includes(ClaimModel.dashboardclub) ||
        currentUser?.menus?.includes(ClaimModel.clubactivity) ||
        currentUser?.menus?.includes(ClaimModel.clubcustomer) ||
        currentUser?.menus?.includes(ClaimModel.clubemployee)) &&
        <AsideMenuItemWithSub
          to='/crafted/clubs'
          title={intl.formatMessage({ id: '0vy' })}
          icon='/media/icons/duotune/communication/club2.svg'
          fontIcon='bi-person'
        >
          {currentUser?.menus?.includes(ClaimModel.dashboardclub) && <AsideMenuItem to='/crafted/clubs/dashboardclub/dashboardclub' title={intl.formatMessage({ id: 'dtq' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.club) && <AsideMenuItem to='/crafted/clubs/club/club' title={intl.formatMessage({ id: '0vy' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.customerclub) && <AsideMenuItem to='/crafted/clubs/customerclub/customerclub' title={intl.formatMessage({ id: '0vy' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.reportclub) && <AsideMenuItem to='/crafted/clubs/reportclub/reportclub' title={intl.formatMessage({ id: 'jfp' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.clubactivity) && <AsideMenuItem to='/crafted/clubs/clubactivity/clubactivity' title={intl.formatMessage({ id: 'pds' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.clubcustomer) && <AsideMenuItem to='/crafted/clubs/clubcustomer/clubcustomer' title={intl.formatMessage({ id: 'fhe' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.clubemployee) && <AsideMenuItem to='/crafted/clubs/clubemployee/clubemployee' title={intl.formatMessage({ id: 'k2j' })} hasBullet={true} />}

        </AsideMenuItemWithSub>
      }

      {(currentUser?.menus?.includes(ClaimModel.attendance) ||
        currentUser?.menus?.includes(ClaimModel.customerattendance) ||
        currentUser?.menus?.includes(ClaimModel.reportattendance) ||
        currentUser?.menus?.includes(ClaimModel.attendancetype)) &&
        <AsideMenuItemWithSub
          to='/crafted/attendances'
          title={intl.formatMessage({ id: '080' })}
          icon='/media/icons/duotune/communication/attendance1.svg'
          fontIcon='bi-person'
        >
          {currentUser?.menus?.includes(ClaimModel.attendance) && <AsideMenuItem to='/crafted/attendances/attendance/attendance' title={intl.formatMessage({ id: '080' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.customerattendance) && <AsideMenuItem to='/crafted/attendances/customerattendance/customerattendance' title={intl.formatMessage({ id: '080' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.reportattendance) && <AsideMenuItem to='/crafted/attendances/reportattendance/reportattendance' title={intl.formatMessage({ id: 'jfp' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.attendancetype) && <AsideMenuItem to='/crafted/attendances/attendancetype/attendancetype' title={intl.formatMessage({ id: 'hmn' })} hasBullet={true} />}


        </AsideMenuItemWithSub>
      }

      {(currentUser?.menus?.includes(ClaimModel.curriculum) ||
        currentUser?.menus?.includes(ClaimModel.sentry) ||
        currentUser?.menus?.includes(ClaimModel.sentrytype) ||
        currentUser?.menus?.includes(ClaimModel.customercurriculum) ||
        currentUser?.menus?.includes(ClaimModel.reportcurriculum) ||
        currentUser?.menus?.includes(ClaimModel.dashboardcurriculum) ||
        currentUser?.menus?.includes(ClaimModel.curriculumsubject) ||
        currentUser?.menus?.includes(ClaimModel.subinstitutionlesson)) &&
        <AsideMenuItemWithSub
          to='/crafted/curricula'
          title={intl.formatMessage({ id: '8pe' })}
          icon='/media/icons/duotune/communication/curriculum1.svg'
          fontIcon='bi-person'
        >
          {currentUser?.menus?.includes(ClaimModel.dashboardcurriculum) && <AsideMenuItem to='/crafted/curricula/dashboardcurriculum/dashboardcurriculum' title={intl.formatMessage({ id: 'dtq' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.curriculum) && <AsideMenuItem to='/crafted/curricula/curriculum/curriculum' title={intl.formatMessage({ id: '8pe' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.sentry) && <AsideMenuItem to='/crafted/curricula/sentry/sentry' title={intl.formatMessage({ id: '801' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.sentrytype) && <AsideMenuItem to='/crafted/curricula/sentrytype/sentrytype' title={intl.formatMessage({ id: 'e1v' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.customercurriculum) && <AsideMenuItem to='/crafted/curricula/customercurriculum/customercurriculum' title={intl.formatMessage({ id: '8pe' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.reportcurriculum) && <AsideMenuItem to='/crafted/curricula/reportcurriculum/reportcurriculum' title={intl.formatMessage({ id: 'jfp' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.curriculumsubject) && <AsideMenuItem to='/crafted/curricula/curriculumsubject/curriculumsubject' title={intl.formatMessage({ id: 'oe6' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.subinstitutionlesson) && <AsideMenuItem to='/crafted/curricula/subinstitutionlesson/subinstitutionlesson' title={intl.formatMessage({ id: 'ryp' })} hasBullet={true} />}

        </AsideMenuItemWithSub>
      }


      {(currentUser?.menus?.includes(ClaimModel.exam) ||
        currentUser?.menus?.includes(ClaimModel.customerexam) ||
        currentUser?.menus?.includes(ClaimModel.reportexam) ||
        currentUser?.menus?.includes(ClaimModel.dashboardexam) ||
        currentUser?.menus?.includes(ClaimModel.exambooklettype) ||
        currentUser?.menus?.includes(ClaimModel.examform) ||
        currentUser?.menus?.includes(ClaimModel.examlessonform) ||
        currentUser?.menus?.includes(ClaimModel.exampart) ||
        currentUser?.menus?.includes(ClaimModel.examresult) ||
        currentUser?.menus?.includes(ClaimModel.examsubject) ||
        currentUser?.menus?.includes(ClaimModel.examtype)) &&
        <AsideMenuItemWithSub
          to='/crafted/exams'
          title={intl.formatMessage({ id: '46h' })}
          icon='/media/icons/duotune/communication/exam1.svg'
          fontIcon='bi-person'
        >
          {currentUser?.menus?.includes(ClaimModel.dashboardexam) && <AsideMenuItem to='/crafted/exams/dashboardexam/dashboardexam' title={intl.formatMessage({ id: 'dtq' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.exam) && <AsideMenuItem to='/crafted/exams/exam/exam' title={intl.formatMessage({ id: '46h' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.customerexam) && <AsideMenuItem to='/crafted/exams/customerexam/customerexam' title={intl.formatMessage({ id: '46h' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.reportexam) && <AsideMenuItem to='/crafted/exams/reportexam/reportexam' title={intl.formatMessage({ id: 'jfp' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.exambooklettype) && <AsideMenuItem to='/crafted/exams/exambooklettype/exambooklettype' title={intl.formatMessage({ id: 'k3x' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.examform) && <AsideMenuItem to='/crafted/exams/examform/examform' title={intl.formatMessage({ id: 'huh' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.examlessonform) && <AsideMenuItem to='/crafted/exams/examlessonform/examlessonform' title={intl.formatMessage({ id: 'fwe' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.exampart) && <AsideMenuItem to='/crafted/exams/exampart/exampart' title={intl.formatMessage({ id: 'ytu' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.examresult) && <AsideMenuItem to='/crafted/exams/examresult/examresult' title={intl.formatMessage({ id: '0qp' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.examsubject) && <AsideMenuItem to='/crafted/exams/examsubject/examsubject' title={intl.formatMessage({ id: 'smv' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.examtype) && <AsideMenuItem to='/crafted/exams/examtype/examtype' title={intl.formatMessage({ id: 'v8t' })} hasBullet={true} />}

        </AsideMenuItemWithSub>
      }

      {(currentUser?.menus?.includes(ClaimModel.survey) ||
        currentUser?.menus?.includes(ClaimModel.customersurvey) ||
        currentUser?.menus?.includes(ClaimModel.reportsurvey) ||
        currentUser?.menus?.includes(ClaimModel.dashboardsurvey) ||
        currentUser?.menus?.includes(ClaimModel.surveyquestion) ||
        currentUser?.menus?.includes(ClaimModel.surveyresult) ||
        currentUser?.menus?.includes(ClaimModel.surveytype)) &&
        <AsideMenuItemWithSub
          to='/crafted/surveys'
          title={intl.formatMessage({ id: '5od' })}
          icon='/media/icons/duotune/communication/survey1.svg'
          fontIcon='bi-person'
        >
          {currentUser?.menus?.includes(ClaimModel.dashboardsurvey) && <AsideMenuItem to='/crafted/surveys/dashboardsurvey/dashboardsurvey' title={intl.formatMessage({ id: 'dtq' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.survey) && <AsideMenuItem to='/crafted/surveys/survey/survey' title={intl.formatMessage({ id: '5od' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.customersurvey) && <AsideMenuItem to='/crafted/surveys/customersurvey/customersurvey' title={intl.formatMessage({ id: '5od' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.reportsurvey) && <AsideMenuItem to='/crafted/surveys/reportsurvey/reportsurvey' title={intl.formatMessage({ id: 'jfp' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.surveyquestion) && <AsideMenuItem to='/crafted/surveys/surveyquestion/surveyquestion' title={intl.formatMessage({ id: 'fg0' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.surveyresult) && <AsideMenuItem to='/crafted/surveys/surveyresult/surveyresult' title={intl.formatMessage({ id: '2ml' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.surveytype) && <AsideMenuItem to='/crafted/surveys/surveytype/surveytype' title={intl.formatMessage({ id: 'ug7' })} hasBullet={true} />}


        </AsideMenuItemWithSub>
      }

      {(currentUser?.menus?.includes(ClaimModel.guidance) ||
        currentUser?.menus?.includes(ClaimModel.customerguidance) ||
        currentUser?.menus?.includes(ClaimModel.dashboardguidance) ||
        currentUser?.menus?.includes(ClaimModel.reportguidance)) &&
        <AsideMenuItemWithSub
          to='/crafted/guidances'
          title={intl.formatMessage({ id: 'sj0' })}
          icon='/media/icons/duotune/communication/guidance1.svg'
          fontIcon='bi-person'
        >
          {currentUser?.menus?.includes(ClaimModel.dashboardguidance) && <AsideMenuItem to='/crafted/guidances/dashboardguidance/dashboardguidance' title={intl.formatMessage({ id: 'dtq' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.guidance) && <AsideMenuItem to='/crafted/guidances/guidance/guidance' title={intl.formatMessage({ id: 'sj0' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.customerguidance) && <AsideMenuItem to='/crafted/guidances/customerguidance/customerguidance' title={intl.formatMessage({ id: 'sj0' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.reportguidance) && <AsideMenuItem to='/crafted/guidances/reportguidance/reportguidance' title={intl.formatMessage({ id: 'jfp' })} hasBullet={true} />}


        </AsideMenuItemWithSub>
      }

      {(currentUser?.menus?.includes(ClaimModel.message) ||
        currentUser?.menus?.includes(ClaimModel.customermessage) ||
        currentUser?.menus?.includes(ClaimModel.reportmessage) ||
        currentUser?.menus?.includes(ClaimModel.messagetype)) &&
        <AsideMenuItemWithSub
          to='/crafted/messages'
          title={intl.formatMessage({ id: 'whs' })}
          icon='/media/icons/duotune/communication/message1.svg'
          fontIcon='bi-person'
        >
          {currentUser?.menus?.includes(ClaimModel.message) && <AsideMenuItem to='/crafted/messages/message/message' title={intl.formatMessage({ id: 'whs' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.customermessage) && <AsideMenuItem to='/crafted/messages/customermessage/customermessage' title={intl.formatMessage({ id: 'whs' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.reportmessage) && <AsideMenuItem to='/crafted/messages/reportmessage/reportmessage' title={intl.formatMessage({ id: 'jfp' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.messagetype) && <AsideMenuItem to='/crafted/messages/messagetype/messagetype' title={intl.formatMessage({ id: 'k97' })} hasBullet={true} />}
        </AsideMenuItemWithSub>
      }


      {(currentUser?.menus?.includes(ClaimModel.ticket) ||
        currentUser?.menus?.includes(ClaimModel.ticketstatu) ||
        currentUser?.menus?.includes(ClaimModel.tickettype)) &&
        <AsideMenuItemWithSub
          to='/crafted/tickets'
          title={intl.formatMessage({ id: 'akg' })}
          icon='/media/icons/duotune/communication/ticket1.svg'
          fontIcon='bi-person'
        >
          {currentUser?.menus?.includes(ClaimModel.ticket) && <AsideMenuItem to='/crafted/tickets/ticket/ticket' title={intl.formatMessage({ id: 'akg' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.ticketstatu) && <AsideMenuItem to='/crafted/tickets/ticketstatu/ticketstatu' title={intl.formatMessage({ id: '747' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.tickettype) && <AsideMenuItem to='/crafted/tickets/tickettype/tickettype' title={intl.formatMessage({ id: '285' })} hasBullet={true} />}
        </AsideMenuItemWithSub>
      }

      {(currentUser?.menus?.includes(ClaimModel.help)) &&
        <AsideMenuItemWithSub
          to='/crafted/helps'
          title={intl.formatMessage({ id: 'r5d' })}
          icon='/media/icons/duotune/communication/help.svg'
          fontIcon='bi-person'
        >
          {currentUser?.menus?.includes(ClaimModel.help) && <AsideMenuItem to='/crafted/helps/help/help' title={intl.formatMessage({ id: 'r5d' })} hasBullet={true} />}
        </AsideMenuItemWithSub>
      }

      {(currentUser?.menus?.includes(ClaimModel.customerpaymentdone) ||
        currentUser?.menus?.includes(ClaimModel.customercustomerpaymentdone) ||
        currentUser?.menus?.includes(ClaimModel.reportcustomerpaymentdone) ||
        currentUser?.menus?.includes(ClaimModel.dashboardcustomerpaymentdone) ||
        currentUser?.menus?.includes(ClaimModel.customerpayment) ||
        currentUser?.menus?.includes(ClaimModel.customerpaymentinstallment) ||
        currentUser?.menus?.includes(ClaimModel.customercustomerpaymentinstallment) ||
        currentUser?.menus?.includes(ClaimModel.reportcustomerpaymentinstallment) ||
        currentUser?.menus?.includes(ClaimModel.paymentmethod) ||
        currentUser?.menus?.includes(ClaimModel.paymentstatu) ||
        currentUser?.menus?.includes(ClaimModel.paymenttype) ||
        currentUser?.menus?.includes(ClaimModel.expense) ||
        currentUser?.menus?.includes(ClaimModel.expensetype) ||
        currentUser?.menus?.includes(ClaimModel.bank) ||
        currentUser?.menus?.includes(ClaimModel.bankaccount) ||
        currentUser?.menus?.includes(ClaimModel.bankbranch)) &&
        <AsideMenuItemWithSub
          to='/crafted/expenses'
          title={intl.formatMessage({ id: 'h01' })}
          icon='/media/icons/duotune/communication/expense1.svg'
          fontIcon='bi-person'
        >
          {currentUser?.menus?.includes(ClaimModel.dashboardcustomerpaymentdone) && <AsideMenuItem to='/crafted/expenses/dashboardcustomerpaymentdone/dashboardcustomerpaymentdone' title={intl.formatMessage({ id: 'dtq' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.customerpaymentdone) && <AsideMenuItem to='/crafted/expenses/customerpaymentdone/customerpaymentdone' title={intl.formatMessage({ id: 'jyo' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.customercustomerpaymentdone) && <AsideMenuItem to='/crafted/expenses/customercustomerpaymentdone/customercustomerpaymentdone' title={intl.formatMessage({ id: 'jyo' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.customerpayment) && <AsideMenuItem to='/crafted/expenses/customerpayment/customerpayment' title={intl.formatMessage({ id: '9qb' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.customerpaymentinstallment) && <AsideMenuItem to='/crafted/expenses/customerpaymentinstallment/customerpaymentinstallment' title={intl.formatMessage({ id: 'ftc' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.customercustomerpaymentinstallment) && <AsideMenuItem to='/crafted/expenses/customercustomerpaymentinstallment/customercustomerpaymentinstallment' title={intl.formatMessage({ id: 'ftc' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.paymentmethod) && <AsideMenuItem to='/crafted/expenses/paymentmethod/paymentmethod' title={intl.formatMessage({ id: 'h7r' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.paymentstatu) && <AsideMenuItem to='/crafted/expenses/paymentstatu/paymentstatu' title={intl.formatMessage({ id: '71f' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.paymenttype) && <AsideMenuItem to='/crafted/expenses/paymenttype/paymenttype' title={intl.formatMessage({ id: '7mo' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.expense) && <AsideMenuItem to='/crafted/expenses/expense/expense' title={intl.formatMessage({ id: 'h01' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.expensetype) && <AsideMenuItem to='/crafted/expenses/expensetype/expensetype' title={intl.formatMessage({ id: 'pwu' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.bank) && <AsideMenuItem to='/crafted/expenses/bank/bank' title={intl.formatMessage({ id: '5gn' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.bankaccount) && <AsideMenuItem to='/crafted/expenses/bankaccount/bankaccount' title={intl.formatMessage({ id: '67z' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.bankbranch) && <AsideMenuItem to='/crafted/expenses/bankbranch/bankbranch' title={intl.formatMessage({ id: '3ay' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.reportcustomerpaymentdone) && <AsideMenuItem to='/crafted/expenses/reportcustomerpaymentdone/reportcustomerpaymentdone' title={intl.formatMessage({ id: 'jfp' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.reportcustomerpaymentinstallment) && <AsideMenuItem to='/crafted/expenses/reportcustomerpaymentinstallment/reportcustomerpaymentinstallment' title={intl.formatMessage({ id: 'ggq' })} hasBullet={true} />}
        </AsideMenuItemWithSub>
      }

      {(currentUser?.menus?.includes(ClaimModel.menu) ||
        currentUser?.menus?.includes(ClaimModel.customermenu) ||
        currentUser?.menus?.includes(ClaimModel.dashboardmenu) ||
        currentUser?.menus?.includes(ClaimModel.reportmenu) ||
        currentUser?.menus?.includes(ClaimModel.food) ||
        currentUser?.menus?.includes(ClaimModel.foodtype) ||
        currentUser?.menus?.includes(ClaimModel.foodingredient) ||
        currentUser?.menus?.includes(ClaimModel.meal) ||
        currentUser?.menus?.includes(ClaimModel.catering) ||
        currentUser?.menus?.includes(ClaimModel.subinstitutioncatering)) &&
        <AsideMenuItemWithSub
          to='/crafted/menus'
          title={intl.formatMessage({ id: 'seu' })}
          icon='/media/icons/duotune/communication/menu1.svg'
          fontIcon='bi-person'
        >
          {currentUser?.menus?.includes(ClaimModel.dashboardmenu) && <AsideMenuItem to='/crafted/menus/dashboardmenu/dashboardmenu' title={intl.formatMessage({ id: 'dtq' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.menu) && <AsideMenuItem to='/crafted/menus/menu/menu' title={intl.formatMessage({ id: '39j' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.customermenu) && <AsideMenuItem to='/crafted/menus/customermenu/customermenu' title={intl.formatMessage({ id: '39j' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.reportmenu) && <AsideMenuItem to='/crafted/menus/reportmenu/reportmenu' title={intl.formatMessage({ id: 'jfp' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.food) && <AsideMenuItem to='/crafted/menus/food/food' title={intl.formatMessage({ id: 'seu' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.foodtype) && <AsideMenuItem to='/crafted/menus/foodtype/foodtype' title={intl.formatMessage({ id: 'wye' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.foodingredient) && <AsideMenuItem to='/crafted/menus/foodingredient/foodingredient' title={intl.formatMessage({ id: 'q1l' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.meal) && <AsideMenuItem to='/crafted/menus/meal/meal' title={intl.formatMessage({ id: 'ta6' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.catering) && <AsideMenuItem to='/crafted/menus/catering/catering' title={intl.formatMessage({ id: 'kdl' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.subinstitutioncatering) && <AsideMenuItem to='/crafted/menus/subinstitutioncatering/subinstitutioncatering' title={intl.formatMessage({ id: 'ert' })} hasBullet={true} />}
        </AsideMenuItemWithSub>
      }

      {(currentUser?.menus?.includes(ClaimModel.shuttle) ||
        currentUser?.menus?.includes(ClaimModel.customershuttle) ||
        currentUser?.menus?.includes(ClaimModel.reportshuttle) ||
        currentUser?.menus?.includes(ClaimModel.dashboardshuttle) ||
        currentUser?.menus?.includes(ClaimModel.shuttlecompany) ||
        currentUser?.menus?.includes(ClaimModel.shuttlecustomer)) &&
        <AsideMenuItemWithSub
          to='/crafted/shuttles'
          title={intl.formatMessage({ id: 'vbj' })}
          icon='/media/icons/duotune/communication/shuttle.svg'
          fontIcon='bi-person'
        >
          {currentUser?.menus?.includes(ClaimModel.dashboardshuttle) && <AsideMenuItem to='/crafted/shuttles/dashboardshuttle/dashboardshuttle' title={intl.formatMessage({ id: 'dtq' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.shuttle) && <AsideMenuItem to='/crafted/shuttles/shuttle/shuttle' title={intl.formatMessage({ id: 'vbj' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.customershuttle) && <AsideMenuItem to='/crafted/shuttles/customershuttle/customershuttle' title={intl.formatMessage({ id: 'vbj' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.reportshuttle) && <AsideMenuItem to='/crafted/shuttles/reportshuttle/reportshuttle' title={intl.formatMessage({ id: 'jfp' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.shuttlecompany) && <AsideMenuItem to='/crafted/shuttles/shuttlecompany/shuttlecompany' title={intl.formatMessage({ id: '7t2' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.shuttlecustomer) && <AsideMenuItem to='/crafted/shuttles/shuttlecustomer/shuttlecustomer' title={intl.formatMessage({ id: 'sve' })} hasBullet={true} />}
        </AsideMenuItemWithSub>
      }

      {(currentUser?.menus?.includes(ClaimModel.medicalintervention) ||
        currentUser?.menus?.includes(ClaimModel.customermedicalintervention) ||
        currentUser?.menus?.includes(ClaimModel.reportmedicalintervention) ||
        currentUser?.menus?.includes(ClaimModel.dashboardmedicalintervention) ||
        currentUser?.menus?.includes(ClaimModel.medicalinterventionresult) ||
        currentUser?.menus?.includes(ClaimModel.medicalinterventiontype) ||
        currentUser?.menus?.includes(ClaimModel.health) ||
        currentUser?.menus?.includes(ClaimModel.bloodgroup)) &&
        <AsideMenuItemWithSub
          to='/crafted/medicalinterventions'
          title={intl.formatMessage({ id: 'rwn' })}
          icon='/media/icons/duotune/communication/health1.svg'
          fontIcon='bi-person'
        >
          {currentUser?.menus?.includes(ClaimModel.dashboardmedicalintervention) && <AsideMenuItem to='/crafted/medicalinterventions/dashboardmedicalintervention/dashboardmedicalintervention' title={intl.formatMessage({ id: 'dtq' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.medicalintervention) && <AsideMenuItem to='/crafted/medicalinterventions/medicalintervention/medicalintervention' title={intl.formatMessage({ id: 'rwn' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.customermedicalintervention) && <AsideMenuItem to='/crafted/medicalinterventions/customermedicalintervention/customermedicalintervention' title={intl.formatMessage({ id: 'rwn' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.reportmedicalintervention) && <AsideMenuItem to='/crafted/medicalinterventions/reportmedicalintervention/reportmedicalintervention' title={intl.formatMessage({ id: 'jfp' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.medicalinterventionresult) && <AsideMenuItem to='/crafted/medicalinterventions/medicalinterventionresult/medicalinterventionresult' title={intl.formatMessage({ id: '6a0' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.medicalinterventiontype) && <AsideMenuItem to='/crafted/medicalinterventions/medicalinterventiontype/medicalinterventiontype' title={intl.formatMessage({ id: '0d6' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.health) && <AsideMenuItem to='/crafted/medicalinterventions/health/health' title={intl.formatMessage({ id: 'snj' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.bloodgroup) && <AsideMenuItem to='/crafted/medicalinterventions/bloodgroup/bloodgroup' title={intl.formatMessage({ id: '4v6' })} hasBullet={true} />}
        </AsideMenuItemWithSub>
      }

      {(currentUser?.menus?.includes(ClaimModel.currency) ||
        currentUser?.menus?.includes(ClaimModel.pricing) ||
        currentUser?.menus?.includes(ClaimModel.offer) ||
        currentUser?.menus?.includes(ClaimModel.offerstatus) ||
        currentUser?.menus?.includes(ClaimModel.offertype) ||
        currentUser?.menus?.includes(ClaimModel.sale) ||
        currentUser?.menus?.includes(ClaimModel.seller)) &&
        <AsideMenuItemWithSub
          to='/crafted/sales'
          title={intl.formatMessage({ id: 'de7' })}
          icon='/media/icons/duotune/communication/sales.svg'
          fontIcon='bi-person'
        >
          {currentUser?.menus?.includes(ClaimModel.sale) && <AsideMenuItem to='/crafted/sales/sale/sale' title={intl.formatMessage({ id: 'de7' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.offer) && <AsideMenuItem to='/crafted/sales/offer/offer' title={intl.formatMessage({ id: 'yzg' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.offertype) && <AsideMenuItem to='/crafted/sales/offertype/offertype' title={intl.formatMessage({ id: 'kcj' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.offerstatus) && <AsideMenuItem to='/crafted/sales/offerstatus/offerstatus' title={intl.formatMessage({ id: 'dd9' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.seller) && <AsideMenuItem to='/crafted/sales/seller/seller' title={intl.formatMessage({ id: '567' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.pricing) && <AsideMenuItem to='/crafted/sales/pricing/pricing' title={intl.formatMessage({ id: '3tc' })} hasBullet={true} />}
          {currentUser?.menus?.includes(ClaimModel.currency) && <AsideMenuItem to='/crafted/sales/currency/currency' title={intl.formatMessage({ id: 'e2s' })} hasBullet={true} />}
        </AsideMenuItemWithSub>
      }

      {(currentUser?.menus?.includes(ClaimModel.gallery)) &&
        <AsideMenuItemWithSub
          to='/crafted/galleries'
          title={intl.formatMessage({ id: 'mme' })}
          icon='/media/icons/duotune/communication/gallery.svg'
          fontIcon='bi-person'
        >

          {currentUser?.menus?.includes(ClaimModel.gallery) && <AsideMenuItem to='/crafted/galleries/gallery/gallery' title={intl.formatMessage({ id: 'mme' })} hasBullet={true} />}
        </AsideMenuItemWithSub>
      }
    </>
  )
}
