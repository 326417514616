/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC, useEffect, useState} from 'react'
import {toAbsoluteUrl} from '../../../helpers'
import {useLang, setLanguage} from '../../../i18n/Metronici18n'
import {useIntl} from 'react-intl'
import {getTerms} from '../../../../app/modules/term/terms-list/core/_requests'
import {Term, initialTerm, initialTerms} from '../../../../app/modules/term/terms-list/core/_models'
import {useAuth} from '../../../../app/modules/auth'
import {useTerm, setTerm, setTermName} from './TermsManager'
import {useYear, setYear} from './YearsManager'


const Terms: FC = () => {
  const intl = useIntl()
  const {currentUser, auth, saveAuth} = useAuth()
  const [terms, setTerms] = useState<Term[]>(initialTerms)
  const [activeTerm, setActiveTerm] = useState<Term>(initialTerm)
  const term = useTerm()
  const year = useYear()

  useEffect(() => {
    const fetchData = async () => {
      getTerms('&subinstitutionId=' + currentUser?.subinstitutionId + '&academicYearId=' + year).then((values) => { 
        setTerms(values.data!) 

        // decide active term
        let selectedTerm = values.data?.find(x => x.id?.toString() === term?.toString())
        if(!selectedTerm && values.data !== undefined)
          selectedTerm = values.data[values.data?.length-1]
        
        if(selectedTerm)
        {
          setTermName(String(selectedTerm.name))
          setTerm(String(selectedTerm.id))
          setActiveTerm(selectedTerm)
        }
        else
        {
          setTermName('')
          setTerm('')
          setActiveTerm(initialTerm)
        }
      }) 
    }

    fetchData().catch(console.error)      

  }, [])

  return (
    <div
      className='menu-item px-2'
      data-kt-menu-trigger='hover'
      data-kt-menu-placement='left-start'
      data-kt-menu-flip='bottom'
    >
      <a href='#' className='menu-link px-2'>
        <span className='menu-title position-relative'>
          {intl.formatMessage({id: 'ahe'})}
          <span className='fs-8 rounded bg-light px-3 py-2 position-absolute translate-middle-y top-50 end-0'>
            {activeTerm?.name}{' '}
          </span>
        </span>
      </a>

      <div className='menu-sub menu-sub-dropdown w-155px py-4'>
        {terms.map((l) => (
          <div
            className='menu-item px-1'
            key={l.name}
            onClick={() => {
              setTermName(String(l.name))
              setTerm(String(l.id))
              window.location.reload()
            }}
          >
            <a
              href='#'
              className={clsx('menu-link d-flex px-2', {active: l.id === activeTerm.id})}
            >
              {l.name}
            </a>
          </div>
        ))}
      </div>
    </div>
  )
}

export {Terms}
