import {ID, Response} from '../../../../../_metronic/helpers'
export type Chat = {
    id?: ID
    
    userId?: number
    userName?: string
    interlocutorUserId?: number
    interlocutorUserName?: string
    type?: string
    text?: string
    time?: Date
    read?: boolean
    createdBy?: number
    createOn?: Date
    updatedBy?: number
    updatedOn?: Date
    status?: number
}

export type UnreadChatCounts = {
    userId?: number
    interlocutorUserId?: number
    unreadCount?: number
}

export type Group = {
    connectionId?: string
    name?: string
}

export type ChatsQueryResponse = Response<Array<Chat>>

export const initialChat: Chat = {
    
    userId: 0,
    userName: '',
    interlocutorUserId: 0,
    interlocutorUserName: '',
    type: '',
    text: '',
    time: new Date(),
    read: true,
    createdBy: undefined,
    createOn: new Date(),
    updatedBy: undefined,
    updatedOn: new Date(),
    status: undefined
}

export const initialChats: Chat[] = [{
    
    userId: 0,
    userName: '',
    interlocutorUserId: 0,
    interlocutorUserName: '',
    type: '',
    text: '',
    time: new Date(),
    read: true,
    createdBy: undefined,
    createOn: new Date(),
    updatedBy: undefined,
    updatedOn: new Date(),
    status: undefined
}]


export const initialUnreadChatCounts: UnreadChatCounts[] = [{
    
    userId: 0,
    interlocutorUserId: 0,
    unreadCount: 0
}]

