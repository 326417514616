
import {FC, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {isNotEmpty} from '../../../../../_metronic/helpers'
import {initialMessage, Message} from '../core/_models'

import {MessageType} from '../../../messagetype/messagetypes-list/core/_models'
import {User} from '../../../apps/user-management/users-list/core/_models'
import clsx from 'clsx'
import {useListView} from '../core/ListViewProvider'
import {MessagesListLoading} from '../components/loading/MessagesListLoading'
import {createMessage, updateMessage} from '../core/_requests'
import {useQueryResponse} from '../core/QueryResponseProvider'
import { useIntl } from "react-intl";
import {success, error} from 'toastr'
import {useAuth} from '../../../auth'
import {RoleModel} from '../../../auth/core/_models'
import {useYear} from '../../../../../_metronic/partials/layout/header-menus/YearsManager'
import {useTerm} from '../../../../../_metronic/partials/layout/header-menus/TermsManager'

type Props = {
  isMessageLoading: boolean
  message: Message
  
  messagetypes: Array<MessageType>
  users: Array<User>
}



const MessageEditModalForm: FC<Props> = ({message, messagetypes,users, isMessageLoading}) => {
  const {setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()
  const intl = useIntl();
  const {currentUser, auth} = useAuth()
  const currentYearId = useYear()
  const currentTermId = useTerm()
  const editMessageSchema = Yup.object().shape({
    
    institutionId: Yup.string().required(intl.formatMessage({ id: "qov" }) + intl.formatMessage({ id: "xdr" })).label('InstitutionId'),
    
    
    messageTypeId: Yup.string().required(intl.formatMessage({ id: "k97" }) + intl.formatMessage({ id: "xdr" })).label('MessageTypeId'),
    
    
    interlocuterUserId: Yup.string().required(intl.formatMessage({ id: "lht" }) + intl.formatMessage({ id: "xdr" })).label('InterlocuterUserId'),
    
    title: Yup.string().required(intl.formatMessage({ id: "jn2" }) + intl.formatMessage({ id: "xdr" })).label('Title'),

    text: Yup.string().required(intl.formatMessage({ id: "hwu" }) + intl.formatMessage({ id: "xdr" })).label('Text'),
    
})
  const [messageForEdit] = useState<Message>({
    ...message,
    
    institutionId: message.institutionId || (currentUser?.institutionId || initialMessage.institutionId ), 
    
    
    
    
    subinstitutionId: message.subinstitutionId || (currentUser?.subinstitutionId || initialMessage.subinstitutionId ), 
     
    
    
    
    messageTypeId: message.messageTypeId || initialMessage.messageTypeId, 
    
    
    
    userId: message.userId || (currentUser?.id || initialMessage.userId), 
    
    
    
    interlocuterUserId: message.interlocuterUserId || initialMessage.interlocuterUserId, 
    
    
    
    title: message.title || initialMessage.title, 


    text: message.text || initialMessage.text, 
    
    
    
    messageDate: message.messageDate || initialMessage.messageDate, 


    academicYearId: message.academicYearId || (currentYearId || initialMessage.academicYearId),
    
    termId: message.termId || (currentTermId || initialMessage.termId),
    
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const formik = useFormik({
    initialValues: messageForEdit,
    validationSchema: editMessageSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        if (isNotEmpty(values.id)) {
          await updateMessage(values)
          success(( intl.formatMessage({ id: "4ga" })),
          ( intl.formatMessage({ id: "w20" })),
          {timeOut: 5000})
        } else {
          await createMessage(values)
          success(( intl.formatMessage({ id: "rcw" })),
          ( intl.formatMessage({ id: "w20" })),
          {timeOut: 5000})
        }
        cancel(true)
      } catch (ex) {
        //console.error(ex)
        error(ex as string,
          ( intl.formatMessage({ id: "7gi" })),
            {timeOut: 5000})
      } finally {
        setSubmitting(true)
      }
    },
  })

  return (
    <>
      <form id='kt_modal_add_message_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_message_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_message_header'
          data-kt-scroll-wrappers='#kt_modal_add_message_scroll'
          data-kt-scroll-offset='300px'
        >
        
        
        <input type='hidden' {...formik.getFieldProps('subinstitutionId')} name='subinstitutionId' />
        <input type='hidden' {...formik.getFieldProps('institutionId')} name='institutionId' />
        <input type='hidden' {...formik.getFieldProps('academicYearId')} name='academicYearId' />
        <input type='hidden' {...formik.getFieldProps('termId')} name='termId' />

        
        {/* begin::Input group */}
          
          
          <div className='fv-row mb-3'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6'>{intl.formatMessage({id: 'k97'})}</label>
            {/* end::Label */}

            {/* begin::Input */}
            <select
              placeholder={intl.formatMessage({id: 'k97'})}
              {...formik.getFieldProps('messageTypeId')}
              name='messageTypeId'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.messageTypeId && formik.errors.messageTypeId},
                {
                  'is-valid': formik.touched.messageTypeId && !formik.errors.messageTypeId,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isMessageLoading}
            >
              <option key='0' value='0'>{ intl.formatMessage({ id: "r2m" })}</option>
              {
                messagetypes.map((messageType, i) => 
                <option key={messageType.id} value={messageType.id!}>
                  {messageType.name}
                </option>
                )
              } 
            </select>
            {formik.touched.messageTypeId && formik.errors.messageTypeId && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.messageTypeId}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          
          
          {/* end::Input group */}
        
        {/* begin::Input group */}
        <input {...formik.getFieldProps('userId')} type='hidden' name='userId'></input>
        
        {/* begin::Input group */}
          
          
          <div className='fv-row mb-3'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6'>{intl.formatMessage({id: 'lht'})}</label>
            {/* end::Label */}

            {/* begin::Input */}
            <select
              placeholder={intl.formatMessage({id: 'lht'})}
              {...formik.getFieldProps('interlocuterUserId')}
              name='interlocuterUserId'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.interlocuterUserId && formik.errors.interlocuterUserId},
                {
                  'is-valid': formik.touched.interlocuterUserId && !formik.errors.interlocuterUserId,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isMessageLoading}
            >
              <option key='0' value='0'>{ intl.formatMessage({ id: "r2m" })}</option>
              {
                users.map((user, i) => 
                <option key={user.id} value={user.id!}>
                  {user.userName}
                </option>
                )
              } 
            </select>
            {formik.touched.interlocuterUserId && formik.errors.interlocuterUserId && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.interlocuterUserId}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          {/* end::Input group */}
        
              
           {/* begin::Input group */}
           <div className='fv-row mb-3'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6'>{intl.formatMessage({id: 'jn2'})}</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder={intl.formatMessage({id: 'jn2'})}
              {...formik.getFieldProps('title')}
              type='text'
              name='title'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.text && formik.errors.text},
                {
                  'is-valid': formik.touched.text && !formik.errors.text,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isMessageLoading}
            />
            {formik.touched.title && formik.errors.title && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.title}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          {/* end::Input group */}

          
          {/* begin::Input group */}
          <div className='fv-row mb-3'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6'>{intl.formatMessage({id: 'hwu'})}</label>
            {/* end::Label */}

            {/* begin::Input */}
            <textarea
              placeholder={intl.formatMessage({id: 'hwu'})}
              {...formik.getFieldProps('text')}
              rows={10}
              name='text'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.text && formik.errors.text},
                {
                  'is-valid': formik.touched.text && !formik.errors.text,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isMessageLoading}
            />
            {formik.touched.text && formik.errors.text && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.text}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          {/* end::Input group */}
          
          {/* begin::Input group */}
          <input {...formik.getFieldProps('messageDate')} type='hidden' name='messageDate'></input>
          {/* end::Input group */}
          
        
        
        
        </div>
        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-messages-modal-action='cancel'
            disabled={formik.isSubmitting || isMessageLoading}
          >
            {intl.formatMessage({id: 'bmg'})}
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-messages-modal-action='submit'
            disabled={isMessageLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>{intl.formatMessage({id: '8y2'})}</span>
            {(formik.isSubmitting || isMessageLoading) && (
              <span className='indicator-progress'>
                {intl.formatMessage({id: 'v3x'})}{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isMessageLoading) && <MessagesListLoading />}
    </>
  )
}

export {MessageEditModalForm}


