import {ID, Response} from '../../../../../_metronic/helpers'
export type Announcement = {
    id?: ID
    
    institutionId?: number
    subinstitutionId?: number
    userId?: number
    title?: string
    content?: string
    startDate?: Date
    endDate?: Date
    imageId?: number
    isCustomer?: boolean
    isPerson?: boolean
    isEmployee?: boolean
    institutionName?: string
    subinstitutionName?: string
    userName?: string
    academicYearId?: number
    termId?: number
}

export type AnnouncementImage = {
    id?: ID
    logoImage?: Blob    
}

export type AnnouncementsQueryResponse = Response<Array<Announcement>>

export const initialAnnouncement: Announcement = {
    
    institutionId: 0,
    subinstitutionId: 0,
    userId: 0,
    title: '',
    content: '',
    startDate: new Date(),
    endDate: new Date(),
    imageId: undefined,
    isCustomer: false,
    isPerson:false,
    isEmployee: false,
    institutionName: '',
    subinstitutionName: '',
    userName: '',
    academicYearId: 0,
    termId: 0
}

export const initialAnnouncements: Announcement[] = [{
    
    institutionId: 0,
    subinstitutionId: 0,
    userId: 0,
    title: '',
    content: '',
    startDate: new Date(),
    endDate: new Date(),
    imageId: undefined,
    isCustomer: false,
    isPerson: false,
    isEmployee: false,
    institutionName: '',
    subinstitutionName: '',
    userName: '',
    academicYearId: 0,
    termId: 0
}]
