import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'
import {ChartModel} from '../../../../../_metronic/partials/widgets/charts/_models'
import {Ticket, TicketsQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const GET_TICKET_URL = `${API_URL}/ticket/getticket`
const GET_TICKET_COUNT_CHART_URL = `${API_URL}/ticket/getticketcountchart`
const GET_TICKET_LESSON_COUNT_CHART_URL = `${API_URL}/ticket/getticketlessoncountchart`
const CREATE_TICKET_URL = `${API_URL}/ticket/addticket`
const UPDATE_TICKET_URL = `${API_URL}/ticket/updateticket`
const DELETE_TICKET_URL = `${API_URL}/ticket/deleteticket`
const GET_TICKETS_URL = `${API_URL}/ticket/gettickets`

const getTickets = (query: string): Promise<TicketsQueryResponse> => {
  return axios
    .get(`${GET_TICKETS_URL}?${query}`)
    .then((d: AxiosResponse<TicketsQueryResponse>) => d.data)
}

const getTicketById = (id: ID): Promise<Ticket | undefined> => {
  return axios
    .get(`${GET_TICKET_URL}/${id}`)
    .then((response: AxiosResponse<Response<Ticket>>) => response.data)
    .then((response: Response<Ticket>) => response.data)
}

const getTicketCountChart = (query: string): Promise<ChartModel | undefined> => {
  return axios
    .get(`${GET_TICKET_COUNT_CHART_URL}?${query}`)
    .then((response: AxiosResponse<Response<ChartModel>>) => response.data)
    .then((response: Response<ChartModel>) => response.data)
}

const getTicketLessonCountChart = (query: string): Promise<ChartModel | undefined> => {
  return axios
    .get(`${GET_TICKET_LESSON_COUNT_CHART_URL}?${query}`)
    .then((response: AxiosResponse<Response<ChartModel>>) => response.data)
    .then((response: Response<ChartModel>) => response.data)
}


const createTicket = (ticket: Ticket): Promise<Ticket | undefined> => {
  return axios
    .post(CREATE_TICKET_URL, ticket)
    .then((response: AxiosResponse<Response<Ticket>>) => response.data)
    .then((response: Response<Ticket>) => response.data)
}

const updateTicket = (ticket: Ticket): Promise<Ticket | undefined> => {
  return axios
    .put(`${UPDATE_TICKET_URL}/${ticket.id}`, ticket)
    .then((response: AxiosResponse<Response<Ticket>>) => response.data)
    .then((response: Response<Ticket>) => response.data)
}

const deleteTicket = (ticketId: ID): Promise<void> => {
  return axios.delete(`${DELETE_TICKET_URL}/${ticketId}`).then(() => {})
}

const deleteSelectedTickets = (ticketIds: Array<ID>): Promise<void> => {
  const requests = ticketIds.map((id) => axios.delete(`${DELETE_TICKET_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {getTickets, deleteTicket, getTicketCountChart, getTicketLessonCountChart, deleteSelectedTickets, getTicketById, createTicket, updateTicket}
