import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'
import {ChartModel} from '../../../../../_metronic/partials/widgets/charts/_models'
import {Curriculum, CurriculaQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const GET_CURRICULUM_URL = `${API_URL}/curriculum/getcurriculum`
const GET_CURRICULUM_COUNT_CHART_URL = `${API_URL}/curriculum/getcurriculumcountchart`
const GET_CURRICULUM_LESSON_COUNT_CHART_URL = `${API_URL}/curriculum/getcurriculumlessoncountchart`
const CREATE_CURRICULUM_URL = `${API_URL}/curriculum/addcurriculum`
const UPDATE_CURRICULUM_URL = `${API_URL}/curriculum/updatecurriculum`
const DELETE_CURRICULUM_URL = `${API_URL}/curriculum/deletecurriculum`
const GET_CURRICULA_URL = `${API_URL}/curriculum/getcurricula`
const GET_CUSTOMER_CURRICULA_URL = `${API_URL}/curriculum/getcustomercurricula`
const GET_CURRICULA_URL_BY_DATE = `${API_URL}/curriculum/getcurriculabydate`

const getCurricula = (query: string): Promise<CurriculaQueryResponse> => {
  return axios
    .get(`${GET_CURRICULA_URL}?${query}`)
    .then((d: AxiosResponse<CurriculaQueryResponse>) => d.data)
}

const getCustomerCurricula = (query: string): Promise<CurriculaQueryResponse> => {
  return axios
    .get(`${GET_CUSTOMER_CURRICULA_URL}?${query}`)
    .then((d: AxiosResponse<CurriculaQueryResponse>) => d.data)
}

const getCurriculaByDate = (query: string): Promise<CurriculaQueryResponse> => {
  return axios
    .get(`${GET_CURRICULA_URL_BY_DATE}?${query}`)
    .then((d: AxiosResponse<CurriculaQueryResponse>) => d.data)
}

const getCurriculumById = (id: ID): Promise<Curriculum | undefined> => {
  return axios
    .get(`${GET_CURRICULUM_URL}/${id}`)
    .then((response: AxiosResponse<Response<Curriculum>>) => response.data)
    .then((response: Response<Curriculum>) => response.data)
}

const getCurriculumCountChart = (query: string): Promise<ChartModel | undefined> => {
  return axios
    .get(`${GET_CURRICULUM_COUNT_CHART_URL}?${query}`)
    .then((response: AxiosResponse<Response<ChartModel>>) => response.data)
    .then((response: Response<ChartModel>) => response.data)
}

const getCurriculumLessonCountChart = (query: string): Promise<ChartModel | undefined> => {
  return axios
    .get(`${GET_CURRICULUM_LESSON_COUNT_CHART_URL}?${query}`)
    .then((response: AxiosResponse<Response<ChartModel>>) => response.data)
    .then((response: Response<ChartModel>) => response.data)
}

const createCurriculum = (Curriculum: Curriculum): Promise<Curriculum | undefined> => {
  return axios
    .post(CREATE_CURRICULUM_URL, Curriculum)
    .then((response: AxiosResponse<Response<Curriculum>>) => response.data)
    .then((response: Response<Curriculum>) => response.data)
}

const updateCurriculum = (Curriculum: Curriculum): Promise<Curriculum | undefined> => {
  return axios
    .put(`${UPDATE_CURRICULUM_URL}/${Curriculum.id}`, Curriculum)
    .then((response: AxiosResponse<Response<Curriculum>>) => response.data)
    .then((response: Response<Curriculum>) => response.data)
}

const deleteCurriculum = (curriculumId: ID): Promise<void> => {
  return axios.delete(`${DELETE_CURRICULUM_URL}/${curriculumId}`).then(() => {})
}

const deleteSelectedCurricula = (curriculumIds: Array<ID>): Promise<void> => {
  const requests = curriculumIds.map((id) => axios.delete(`${DELETE_CURRICULUM_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

let eventGuid = 0
const createEventId = () => {
  return String(eventGuid++)
}

export {getCurricula, getCustomerCurricula, getCurriculaByDate, deleteCurriculum, deleteSelectedCurricula, getCurriculumById, getCurriculumCountChart, getCurriculumLessonCountChart, createCurriculum, updateCurriculum, createEventId}
