import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'
import {ChartModel} from '../../../../../_metronic/partials/widgets/charts/_models'
import {Employee, EmployeeImage, EmployeesQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const GET_EMPLOYEE_URL = `${API_URL}/employee/getemployee`
const GET_EMPLOYEE_COUNT_URL = `${API_URL}/employee/getemployeecount`
const GET_EMPLOYEE_COUNT_CHART_URL = `${API_URL}/employee/getemployeecountchart`
const GET_EMPLOYEE_LESSON_COUNT_CHART_URL = `${API_URL}/employee/getemployeelessoncountchart`
const CREATE_EMPLOYEE_URL = `${API_URL}/employee/addemployee`
const UPDATE_EMPLOYEE_URL = `${API_URL}/employee/updateemployee`
const DELETE_EMPLOYEE_URL = `${API_URL}/employee/deleteemployee`
const GET_EMPLOYEES_URL = `${API_URL}/employee/getemployees`

const getEmployees = (query: string): Promise<EmployeesQueryResponse> => {
  return axios
    .get(`${GET_EMPLOYEES_URL}?${query}`)
    .then((d: AxiosResponse<EmployeesQueryResponse>) => d.data)
}

const getEmployeeById = (id: ID): Promise<Employee | undefined> => {
  return axios
    .get(`${GET_EMPLOYEE_URL}/${id}`)
    .then((response: AxiosResponse<Response<Employee>>) => response.data)
    .then((response: Response<Employee>) => response.data)
}

const getEmployeeCount = (institutionId: ID): Promise<number | undefined> => {
  return axios
    .get(`${GET_EMPLOYEE_COUNT_URL}/${institutionId}`)
    .then((response: AxiosResponse<Response<number>>) => response.data)
    .then((response: Response<number>) => response.data)
}

const getEmployeeCountChart = (query: string): Promise<ChartModel | undefined> => {
  return axios
    .get(`${GET_EMPLOYEE_COUNT_CHART_URL}?${query}`)
    .then((response: AxiosResponse<Response<ChartModel>>) => response.data)
    .then((response: Response<ChartModel>) => response.data)
}

const getEmployeeLessonCountChart = (query: string): Promise<ChartModel | undefined> => {
  return axios
    .get(`${GET_EMPLOYEE_LESSON_COUNT_CHART_URL}?${query}`)
    .then((response: AxiosResponse<Response<ChartModel>>) => response.data)
    .then((response: Response<ChartModel>) => response.data)
}

const createEmployee = (employee: Employee): Promise<Employee | undefined> => {
  return axios
    .post(CREATE_EMPLOYEE_URL, employee)
    .then((response: AxiosResponse<Response<Employee>>) => response.data)
    .then((response: Response<Employee>) => response.data)
}

const updateEmployee = (employee: Employee): Promise<Employee | undefined> => {
  return axios
    .put(`${UPDATE_EMPLOYEE_URL}/${employee.id}`, employee)
    .then((response: AxiosResponse<Response<Employee>>) => response.data)
    .then((response: Response<Employee>) => response.data)
}

const deleteEmployee = (employeeId: ID): Promise<void> => {
  return axios.delete(`${DELETE_EMPLOYEE_URL}/${employeeId}`).then(() => {})
}

const deleteSelectedEmployees = (employeeIds: Array<ID>): Promise<void> => {
  const requests = employeeIds.map((id) => axios.delete(`${DELETE_EMPLOYEE_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {getEmployees, getEmployeeCount, deleteEmployee, getEmployeeLessonCountChart, getEmployeeCountChart, deleteSelectedEmployees, getEmployeeById, createEmployee, updateEmployee}
