import React, {FC} from 'react'
import {DefaultTitle} from './page-title/DefaultTitle'

const Header: FC = () => {
  return (
    <>
    <div className='d-flex align-items-center' id='kt_header_nav'>
      <DefaultTitle />
    </div>
  </>
  )
}

export {Header}
