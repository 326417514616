export interface AuthModel {
  api_token: string
  refreshToken?: string
  currentRole?: string
  currentTerm?: string
  currentYear?: string
}

export interface UserAddressModel {
  addressLine: string
  city: string
  state: string
  postCode: string
}

export interface UserCommunicationModel {
  email: boolean
  sms: boolean
  phone: boolean
}

export interface UserEmailSettingsModel {
  emailNotification?: boolean
  sendCopyToPersonalEmail?: boolean
  activityRelatesEmail?: {
    youHaveNewNotifications?: boolean
    youAreSentADirectMessage?: boolean
    someoneAddsYouAsAsAConnection?: boolean
    uponNewOrder?: boolean
    newMembershipApproval?: boolean
    memberRegistration?: boolean
  }
  updatesFromKeenthemes?: {
    newsAboutKeenthemesProductsAndFeatureUpdates?: boolean
    tipsOnGettingMoreOutOfKeen?: boolean
    thingsYouMissedSindeYouLastLoggedIntoKeen?: boolean
    newsAboutStartOnPartnerProductsAndOtherServices?: boolean
    tipsOnStartBusinessProducts?: boolean
  }
}

export interface UserSocialNetworksModel {
  linkedIn: string
  facebook: string
  twitter: string
  instagram: string
}

export interface UserModel {
  id: number
  username?: string
  email?: string
  first_Name?: string
  last_Name?: string
  fullName?: string
  roles?: Array<string>
  menus?: Array<string>
  claims?: Array<string>
  institutionId?: number
  subinstitutionId?: number
  customerId?: number
  employeeId?: number
  personId?: number
  sellerId?: number
  imageId?: number
}

export interface H5PUserModel {
  id: string,
  email: string,  
  username: string,
  name: string,
  role: string,
  csrfToken: string
}

export const initialH5PUser: H5PUserModel = {
  id: '',
  email: '',  
  username: '',
  name: '',
  role: '',
  csrfToken: ''
}

export type BaseType = {
  createdBy?: number,
  createdOn?: Date,
  updatedBy?: number,
  updatedOn?: Date,
  status?: number
};

export enum PublishingOptionModel {
  OpraziOpe=1,
  Public=2,
  MyInstitution=3,
  MySubinstitution=4
}

export enum RoleModel {
  Admin="Admin",
  InstitutionManager="Institution Manager",
  SchoolManager="School Manager",
  Teacher="Teacher",
  StudyManager="Study Manager",
  HomeworkManager="Homework Manager",
  DeskManager="Desk Manager",
  InstitutionAdmin="Institution Admin",
  SchoolAdmin="School Admin",
  Customer="Customer",
  Person="Person",
  Employee="Employee",
  Accounter="Accounter",
  AnnouncementManager="Announcement Manager",
  AuthManager="Auth Manager",
  EmployeeManager="Employee Manager",
  CustomerManager="Customer Manager",
  ClubManager="Club Manager",
  AttendanceManager="Attendance Manager",
  CurriculumManager="Curriculum Manager",
  ExamManager="Exam Manager",
  SurveyManager="Survey Manager",
  GuidanceManager="Guidance Manager",
  FoodManager="Food Manager",
  ShuttleManager="Shuttle Manager",
  HealthManager="Health Manager",
  Seller="Seller",
  GalleryManager="Gallery Manager",
  CourseManager="Course Manager"
}

enum Weekend {
  Friday = 1,
  Saturday = 2,
  Sunday = 3
}

export enum ClaimModel {
  academicyear='jk',
academicyear_create='uo',
academicyear_delete='8p',
academicyear_update='rh',
academicyear_view='2a',
announcement='2f',
announcement_create='06',
announcement_delete='vj',
announcement_update='5i',
announcement_view='xw',
assignment='m1',
assignment_create='00',
assignment_delete='7g',
assignment_update='hp',
assignment_view='py',
assignmentanswer='2i',
assignmentanswer_create='ds',
assignmentanswer_delete='0n',
assignmentanswer_update='10',
assignmentanswer_view='q6',
assignmentgrade='us',
assignmentgrade_create='zf',
assignmentgrade_delete='vs',
assignmentgrade_update='9e',
assignmentgrade_view='ry',
attendance='bu',
attendance_create='gu',
attendance_delete='g2',
attendance_update='px',
attendance_view='7k',
attendancetype='xu',
attendancetype_create='oc',
attendancetype_delete='s1',
attendancetype_update='kx',
attendancetype_view='m2',
bank='gl',
bank_create='58',
bank_delete='m3',
bank_update='k7',
bank_view='da',
bankaccount='ql',
bankaccount_create='sy',
bankaccount_delete='gs',
bankaccount_update='4a',
bankaccount_view='6c',
bankbranch='bc',
bankbranch_create='9h',
bankbranch_delete='wh',
bankbranch_update='fw',
bankbranch_view='wd',
bloodgroup='49',
bloodgroup_create='hd',
bloodgroup_delete='nd',
bloodgroup_update='bp',
bloodgroup_view='7y',
catering='yu',
catering_create='7s',
catering_delete='6k',
catering_update='zw',
catering_view='aj',
city='ro',
city_create='z7',
city_delete='kf',
city_update='2z',
city_view='68',
class='95',
class_create='sr',
class_delete='lb',
class_update='ru',
class_view='mb',
club='s0',
club_create='wa',
club_delete='3r',
club_update='5y',
club_view='ee',
clubactivity='97',
clubactivity_create='lk',
clubactivity_delete='j8',
clubactivity_update='gd',
clubactivity_view='79',
clubcustomer='sb',
clubcustomer_create='n7',
clubcustomer_delete='7e',
clubcustomer_update='zm',
clubcustomer_view='cn',
clubemployee='59',
clubemployee_create='ww',
clubemployee_delete='z6',
clubemployee_update='dd',
clubemployee_view='66',
country='fl',
country_create='zv',
country_delete='wl',
country_update='ym',
country_view='4j',
curriculum='v8',
curriculum_create='1b',
curriculum_delete='7n',
curriculum_update='87',
curriculum_view='a8',
curriculumsubject='9j',
curriculumsubject_create='1x',
curriculumsubject_delete='rc',
curriculumsubject_update='eu',
curriculumsubject_view='82',
customer='sc',
customerannouncement='y0',
customerassignment='jb',
customerattendance='8s',
customerclub='sq',
customerclubcustomer='s3',
customercurriculum='jv',
customercustomerpaymentinstallment='ps',
customerexam='ed',
customerguidance='be',
customerinfo='i5',
customermedicalintervention='8v',
customermenu='86',
customermessage='ri',
customerpayment='pa',
customerpaymentdone='eo',
customercustomerpaymentdone='02',
customerpaymentinstallment='dr',
customershuttle='td',
customershuttlecustomer='5b',
customerstudy='t5',
customersubinstitutionlesson='qd',
customersurvey='sd',
dashboard='aa',
dashboardannouncement='50',
dashboardassignment='zr',
dashboardclub='sl',
dashboardcurriculum='zt',
dashboardcustomer='jn',
dashboardcustomerpaymentdone='ir',
dashboardemployee='xc',
dashboardexam='65',
dashboardguidance='5l',
dashboardmedicalintervention='18',
dashboardmenu='ea',
dashboardshuttle='gf',
dashboardstudy='pv',
dashboardsurvey='b3',
employee='jt',
employee_create='jx',
employee_delete='mf',
employee_update='56',
employee_view='wq',
employeelesson='vp',
employeelesson_create='lt',
employeelesson_delete='4s',
employeelesson_update='y2',
employeelesson_view='rx',
employeetype='11',
employeetype_create='d1',
employeetype_delete='qq',
employeetype_update='kj',
employeetype_view='j9',
exam='y9',
exam_create='c7',
exam_delete='99',
exam_update='sf',
exam_view='ez',
exambooklettype='iy',
exambooklettype_create='9s',
exambooklettype_delete='ev',
exambooklettype_update='yk',
exambooklettype_view='va',
examform='5s',
examform_create='k9',
examform_delete='cc',
examform_update='ib',
examform_view='we',
examlessonform='hn',
examlessonform_create='w4',
examlessonform_delete='w7',
examlessonform_update='3o',
examlessonform_view='hg',
exampart='3u',
exampart_create='sw',
exampart_delete='cj',
exampart_update='0k',
exampart_view='7r',
examresult='pl',
examresult_create='69',
examresult_delete='8z',
examresult_update='2k',
examresult_view='ih',
examsubject='t0',
examsubject_create='i7',
examsubject_delete='ec',
examsubject_update='2d',
examsubject_view='n9',
examtype='by',
examtype_create='5e',
examtype_delete='s5',
examtype_update='ki',
examtype_view='l6',
expense='ot',
expense_create='2e',
expense_delete='ut',
expense_update='c8',
expense_view='k1',
expensetype='k8',
expensetype_create='pb',
expensetype_delete='d7',
expensetype_update='y5',
expensetype_view='7v',
food='jd',
food_create='1s',
food_delete='rr',
food_update='z1',
food_view='1y',
foodingredient='o2',
foodingredient_create='gq',
foodingredient_delete='s9',
foodingredient_update='sg',
foodingredient_view='o0',
foodtype='8o',
foodtype_create='41',
foodtype_delete='bd',
foodtype_update='tu',
foodtype_view='a2',
gender='po',
gender_create='xp',
gender_delete='vd',
gender_update='oe',
gender_view='7d',
guidance='h7',
guidance_create='gz',
guidance_delete='t2',
guidance_update='fr',
guidance_view='3w',
health='gx',
health_create='3m',
health_delete='6j',
health_update='ci',
health_view='4k',
help='09',
help_create='bj',
help_delete='r3',
help_update='ti',
help_view='rz',
institution='6q',
institution_create='df',
institution_delete='3x',
institution_update='qk',
institution_view='j0',
language='8c',
language_create='zs',
language_delete='h6',
language_update='0u',
language_view='fa',
lesson='6n',
lesson_create='2r',
lesson_delete='ne',
lesson_update='fi',
lesson_view='t9',
meal='88',
meal_create='eb',
meal_delete='wt',
meal_update='2b',
meal_view='yn',
medicalintervention='g8',
medicalintervention_create='s8',
medicalintervention_delete='mc',
medicalintervention_update='no',
medicalintervention_view='7x',
medicalinterventionresult='yb',
medicalinterventionresult_create='d2',
medicalinterventionresult_delete='r6',
medicalinterventionresult_update='ui',
medicalinterventionresult_view='wm',
medicalinterventiontype='21',
medicalinterventiontype_create='qa',
medicalinterventiontype_delete='u9',
medicalinterventiontype_update='dc',
medicalinterventiontype_view='qc',
menu='14',
menu_create='8q',
menu_delete='qz',
menu_update='2j',
menu_view='h8',
message='7i',
message_create='4y',
message_delete='vf',
message_update='mi',
message_view='1j',
messagetype='t7',
messagetype_create='lx',
messagetype_delete='xk',
messagetype_update='g5',
messagetype_view='9c',
paymentmethod='rq',
paymentmethod_create='i8',
paymentmethod_delete='70',
paymentmethod_update='gt',
paymentmethod_view='lh',
paymentstatu='4n',
paymentstatu_create='rb',
paymentstatu_delete='13',
paymentstatu_update='pg',
paymentstatu_view='40',
paymenttype='6i',
paymenttype_create='0t',
paymenttype_delete='9b',
paymenttype_update='b8',
paymenttype_view='ks',
person='md',
person_create='xn',
person_delete='x1',
person_update='me',
person_view='ab',
persontype='nk',
persontype_create='xa',
persontype_delete='cr',
persontype_update='1h',
persontype_view='je',
place='db',
place_create='fm',
place_delete='mw',
place_update='05',
place_view='07',
reportannouncement='lo',
reportannouncement_create='ce',
reportannouncement_delete='r8',
reportannouncement_update='lm',
reportannouncement_view='vr',
reportassignment='gr',
reportassignment_create='ht',
reportassignment_delete='1l',
reportassignment_update='5r',
reportassignment_view='9p',
reportattendance='cq',
reportattendance_create='j3',
reportattendance_delete='1d',
reportattendance_update='64',
reportattendance_view='3v',
reportclub='kt',
reportclub_create='vo',
reportclub_delete='ix',
reportclub_view='l5',
reportcurriculum='an',
reportcurriculum_create='lu',
reportcurriculum_delete='1q',
reportcurriculum_update='wp',
reportcurriculum_view='72',
reportcustomerpaymentdone='0x',
reportcustomerpaymentdone_create='na',
reportcustomerpaymentdone_delete='4x',
reportcustomerpaymentdone_update='n5',
reportcustomerpaymentdone_view='ls',
reportcustomerpaymentinstallment='d8',
reportcustomerpaymentinstallment_create='8x',
reportcustomerpaymentinstallment_delete='bs',
reportcustomerpaymentinstallment_update='8k',
reportcustomerpaymentinstallment_view='ia',
reportexam='yw',
reportexam_create='94',
reportexam_delete='m6',
reportexam_update='x6',
reportexam_view='e1',
reportguidance='z0',
reportguidance_create='2n',
reportguidance_delete='ex',
reportguidance_update='fv',
reportguidance_view='b5',
reportmedicalintervention='tl',
reportmedicalintervention_create='ar',
reportmedicalintervention_delete='ky',
reportmedicalintervention_update='f5',
reportmedicalintervention_view='uh',
reportmenu='0z',
reportmenu_create='ty',
reportmenu_delete='31',
reportmenu_update='ya',
reportmenu_view='pr',
reportmessage='zb',
reportmessage_create='c5',
reportmessage_delete='il',
reportmessage_update='fh',
reportmessage_view='de',
reportshuttle='42',
reportshuttle_create='og',
reportshuttle_delete='vy',
reportshuttle_update='sh',
reportshuttle_view='j1',
reportstudy='um',
reportstudy_create='5m',
reportstudy_delete='kn',
reportstudy_update='3e',
reportstudy_view='c6',
reportsurvey='t1',
reportsurvey_create='a3',
reportsurvey_delete='vu',
reportsurvey_update='hz',
reportsurvey_view='bt',
role='au',
role_create='f3',
role_delete='y6',
role_update='5h',
role_view='h3',
roleclaim='i1',
roleclaim_create='2u',
roleclaim_delete='nl',
roleclaim_update='ku',
roleclaim_view='d9',
sentry='7l',
sentry_create='zn',
sentry_delete='p0',
sentry_update='kc',
sentry_view='36',
sentrytype='ik',
sentrytype_create='g7',
sentrytype_delete='2l',
sentrytype_update='im',
sentrytype_view='ln',
shuttle='t8',
shuttle_create='v5',
shuttle_delete='e4',
shuttle_update='vz',
shuttle_view='ld',
shuttlecompany='3p',
shuttlecompany_create='qy',
shuttlecompany_delete='1k',
shuttlecompany_update='9r',
shuttlecompany_view='gg',
shuttlecustomer='cu',
shuttlecustomer_create='x5',
shuttlecustomer_delete='o6',
shuttlecustomer_update='zu',
shuttlecustomer_view='mh',
study='dz',
study_create='pc',
study_delete='ka',
study_update='d6',
study_view='n1',
studyeventtype='9o',
studyeventtype_create='ug',
studyeventtype_delete='nq',
studyeventtype_update='12',
studyeventtype_view='1o',
studytype='h2',
studytype_create='nm',
studytype_delete='5j',
studytype_update='x2',
studytype_view='j7',
subinstitution='f9',
subinstitution_create='i4',
subinstitution_delete='uz',
subinstitution_update='nv',
subinstitution_view='o3',
subinstitutioncatering='yg',
subinstitutioncatering_create='7o',
subinstitutioncatering_delete='gk',
subinstitutioncatering_update='h1',
subinstitutioncatering_view='zh',
subinstitutionlesson='jr',
subinstitutionlesson_create='6l',
subinstitutionlesson_delete='jh',
subinstitutionlesson_update='5t',
subinstitutionlesson_view='n0',
subinstitutiontype='ys',
subinstitutiontype_create='zp',
subinstitutiontype_delete='et',
subinstitutiontype_update='ay',
subinstitutiontype_view='kr',
subinstitutiontypebranch='ax',
subinstitutiontypebranch_create='rl',
subinstitutiontypebranch_delete='ie',
subinstitutiontypebranch_update='g1',
subinstitutiontypebranch_view='cp',
subject='44',
subject_create='nn',
subject_delete='ct',
subject_update='7q',
subject_view='sj',
survey='8m',
survey_create='q9',
survey_delete='ap',
survey_update='6b',
survey_view='8r',
surveyquestion='qb',
surveyquestion_create='m7',
surveyquestion_delete='7f',
surveyquestion_update='wf',
surveyquestion_view='hf',
surveyresult='ek',
surveyresult_create='ak',
surveyresult_delete='m4',
surveyresult_update='rt',
surveyresult_view='4q',
surveytype='tq',
surveytype_create='yo',
surveytype_delete='tw',
surveytype_update='4e',
surveytype_view='hy',
term='37',
term_create='yq',
term_delete='nx',
term_update='fx',
term_view='dm',
test='en',
test_create='5n',
test_delete='hj',
test_update='0v',
test_view='r7',
ticket='u8',
ticket_create='ua',
ticket_delete='hx',
ticket_update='nr',
ticket_view='47',
ticketstatu='zy',
ticketstatu_create='fp',
ticketstatu_delete='hm',
ticketstatu_update='w3',
ticketstatu_view='8t',
tickettype='v0',
tickettype_create='wy',
tickettype_delete='n8',
tickettype_update='ur',
tickettype_view='cl',
unit='x9',
unit_create='yx',
unit_delete='43',
unit_update='wk',
unit_view='d0',
unittype='iv',
unittype_create='it',
unittype_delete='rv',
unittype_update='iz',
unittype_view='kp',
user='jw',
user_create='y3',
user_delete='74',
user_update='16',
user_view='n3',
userclaim='lq',
userclaim_create='75',
userclaim_delete='6t',
userclaim_update='em',
userclaim_view='2g',
userlogin='6s',
userlogin_create='q2',
userlogin_delete='dj',
userlogin_update='2p',
userlogin_view='tb',
userrole='4f',
userrole_create='zq',
userrole_delete='vl',
userrole_update='yc',
userrole_view='xd',
users='67',
users_create='y1',
users_delete='ca',
users_update='ho',
users_view='pi',
workingstatue='sp',
workingstatue_create='3n',
workingstatue_delete='cf',
workingstatue_update='k6',
workingstatue_view='sz',
customer_create='6g',
customer_delete='mj',
customer_update='5k',
customer_view='of',
customerpayment_create='cv',
customerpayment_update='o5',
customerpayment_delete='er',
customerpayment_view='q3',
customerpaymentdone_create='uj',
customerpaymentdone_update='iq',
customerpaymentdone_delete='ni',
customerpaymentdone_view='e6',
customerpaymentinstallment_create='ep',
customerpaymentinstallment_update='oy',
customerpaymentinstallment_delete='1p',
customerpaymentinstallment_view='0g',
chat='lp',
chat_create='uu',
chat_delete='cg',
chat_update='dl',
chat_view='w6',
subinstitutionsubinstitutiontype='p5',
subinstitutionsubinstitutiontype_create='kk',
subinstitutionsubinstitutiontype_delete='yl',
subinstitutionsubinstitutiontype_update='3l',
subinstitutionsubinstitutiontype_view='4z',
examquestionresult='jm',
examquestionresult_create='xo',
examquestionresult_delete='j4',
examquestionresult_update='qe',
examquestionresult_view='3d',
examlessonresult='kv',
examlessonresult_create='rg',
examlessonresult_delete='78',
examlessonresult_update='0d',
examlessonresult_view='4r',
assignmentfile='kh',
assignmentfile_create='f2',
assignmentfile_delete='4u',
assignmentfile_update='su',
assignmentfile_view='wi',
assignmentanswerfile='kl',
assignmentanswerfile_create='4d',
assignmentanswerfile_delete='dt',
assignmentanswerfile_update='j6',
assignmentanswerfile_view='vk',
currency='jg',
currency_create='mo',
currency_delete='v3',
currency_update='qi',
currency_view='vq',
pricing='0r',
pricing_create='c3',
pricing_delete='nz',
pricing_update='28',
pricing_view='3g',
offer='1z',
offer_create='uq',
offer_delete='sv',
offer_update='r0',
offer_view='ws',
offerstatus='b0',
offerstatus_create='l3',
offerstatus_delete='3t',
offerstatus_update='6h',
offerstatus_view='za',
offertype='f0',
offertype_create='am',
offertype_delete='bw',
offertype_update='6e',
offertype_view='io',
sale='rk',
sale_create='a9',
sale_delete='h5',
sale_update='dy',
sale_view='7b',
seller='so',
seller_create='v2',
seller_delete='3y',
seller_update='u6',
seller_view='5u',
customerexamresult='6m',
filetype='ng',
filetype_create='4o',
filetype_delete='2w',
filetype_update='3q',
filetype_view='vh',
fileshare='wz',
fileshare_create='cm',
fileshare_delete='al',
fileshare_update='tm',
fileshare_view='i0',
gallery='rf',
gallery_create='6a',
gallery_delete='1f',
gallery_update='52',
gallery_view='di',
file='j2',
file_create='1m',
file_delete='p9',
file_update='cz',
file_view='hh',
module='83',
module_create='4g',
module_delete='f6',
module_update='ch',
module_view='wn',
page='lr',
page_create='9l',
page_delete='u7',
page_update='3j',
page_view='20',
setting='bo',
setting_create='b7',
setting_delete='6v',
setting_update='q5',
setting_view='sx',
claimvalue='fz',
claimvalue_create='2t',
claimvalue_delete='rs',
claimvalue_update='0j',
claimvalue_view='eh',
moduleinstitution='az',
moduleinstitution_create='8y',
moduleinstitution_delete='ll',
moduleinstitution_update='0p',
moduleinstitution_view='wb',
logs='nh',
logs_create='2c',
logs_delete='wx',
logs_update='oi',
logs_view='xl',
customeracademicyear='96',
customeracademicyear_create='9k',
customeracademicyear_delete='ha',
customeracademicyear_update='0b',
customeracademicyear_view='ny',
personacademicyear='i9',
personacademicyear_create='rn',
personacademicyear_delete='re',
personacademicyear_update='48',
personacademicyear_view='9y',
shuttleacademicyear='5w',
shuttleacademicyear_create='se',
shuttleacademicyear_delete='v4',
shuttleacademicyear_update='pd',
shuttleacademicyear_view='8g',
    course='bb',
    course_create='mm',
    course_delete='jj',
    course_update='ii',
    course_view='i6',
  
    coursetype='a1',
    coursetype_create='a4',
    coursetype_delete='a5',
    coursetype_update='a6',
    coursetype_view='a7',
  
    courselevel='a0',
    courselevel_create='b1',
    courselevel_delete='b2',
    courselevel_update='b4',
    courselevel_view='b6',
  
    
    proficiencytest='b9',
    proficiencytest_create='c1',
    proficiencytest_delete='c2',
    proficiencytest_update='c4',
    proficiencytest_view='c9',
  
    courseskillquestion='z2',
    courseskillquestion_create='z3',
    courseskillquestion_delete='z4',
    courseskillquestion_update='z5',
    courseskillquestion_view='z8',
    
    courseskill='t3',
    courseskill_create='t4',
    courseskill_delete='t6',
    courseskill_update='p1',
    courseskill_view='p2',
  
    courseresult='o1',
    courseresult_create='o4',
    courseresult_delete='o7',
    courseresult_update='o8',
    courseresult_view='o9',
  
    courseresulttype='p3',
    courseresulttype_create='p4',
    courseresulttype_delete='p6',
    courseresulttype_update='p7',
    courseresulttype_view='p8',
  
    courseskillfeedback='x0',
    courseskillfeedback_create='x3',
    courseskillfeedback_delete='x4',
    courseskillfeedback_update='x7',
    courseskillfeedback_view='x8',
    
    courseresultcriteria='y4',
    courseresultcriteria_create='y7',
    courseresultcriteria_delete='y8',
    courseresultcriteria_update='v1',
    courseresultcriteria_view='v6',
    
    courseschedule='v7',
    courseschedule_create='v9',
    courseschedule_delete='q0',
    courseschedule_update='q1',
    courseschedule_view='q4',
    
    publishingoption='q7',
    publishingoption_create='q8',
    publishingoption_delete='r1',
    publishingoption_update='r2',
    publishingoption_view='r4',
    
}

export interface ErrorResponse {
  type: string
  message: string
  stackTrace: string
}