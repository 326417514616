import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'
import {City, CitiesQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const GET_CITY_URL = `${API_URL}/city/getcity`
const CREATE_CITY_URL = `${API_URL}/city/addcity`
const UPDATE_CITY_URL = `${API_URL}/city/updatecity`
const DELETE_CITY_URL = `${API_URL}/city/deletecity`
const GET_CITIES_URL = `${API_URL}/city/getcities`

const getCities = (query: string): Promise<CitiesQueryResponse> => {
  return axios
    .get(`${GET_CITIES_URL}?${query}`)
    .then((d: AxiosResponse<CitiesQueryResponse>) => d.data)
}

const getCityById = (id: ID): Promise<City | undefined> => {
  return axios
    .get(`${GET_CITY_URL}/${id}`)
    .then((response: AxiosResponse<Response<City>>) => response.data)
    .then((response: Response<City>) => response.data)
}

const createCity = (city: City): Promise<City | undefined> => {
  return axios
    .post(CREATE_CITY_URL, city)
    .then((response: AxiosResponse<Response<City>>) => response.data)
    .then((response: Response<City>) => response.data)
}

const updateCity = (city: City): Promise<City | undefined> => {
  return axios
    .put(`${UPDATE_CITY_URL}/${city.id}`, city)
    .then((response: AxiosResponse<Response<City>>) => response.data)
    .then((response: Response<City>) => response.data)
}

const deleteCity = (cityId: ID): Promise<void> => {
  return axios.delete(`${DELETE_CITY_URL}/${cityId}`).then(() => {})
}

const deleteSelectedCities = (cityIds: Array<ID>): Promise<void> => {
  const requests = cityIds.map((id) => axios.delete(`${DELETE_CITY_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {getCities, deleteCity, deleteSelectedCities, getCityById, createCity, updateCity}
