/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {useAuth} from '../../../app/modules/auth'
import {RoleModel} from '../../../app/modules/auth/core/_models'
import {PageTitle} from '../../../_metronic/layout/core'
import {
  InstitutionLineStatisticWidget,
  CustomerLineStatisticWidget,
  WhatsUpTodayTableWidget,
  AnnouncementTableWidgetWrapper,
  MessageTableWidget,
  AssignmentLessonCountChartWidget,
  CustomerPaymentDoneLessonCountChartWidget,
  CustomerLessonCountChartWidget,
  StudyLessonCountChartWidget,
  AnnouncementLessonCountChartWidget,
  ExamCountChartWidget,
  EmployeeLessonCountChartWidget,
  ClubLessonCountChartWidget,
  MedicalInterventionLessonCountChartWidget
} from '../../../_metronic/partials/widgets'

const DashboardPage: FC = () => {
  const {currentUser, auth} = useAuth()

  return(

  <>
    
    {auth?.currentRole===RoleModel.InstitutionManager ||
    auth?.currentRole===RoleModel.SchoolManager ?
    ( 
    <div className='row'>
      <div className='col-lg-12'>
        <InstitutionLineStatisticWidget className='card-xl-stretch mb-xl-8' />
      </div>    
    </div>
    )
    : <></>
    }

    {auth?.currentRole===RoleModel.Customer || auth?.currentRole===RoleModel.Person ?
    ( 
    <div className='row'>
      <div className='col-lg-12'>
        <CustomerLineStatisticWidget className='card-xl-stretch mb-xl-8' />
      </div>    
    </div>
    )
    : <></>
    }

    {/*<div className='row'>
      <div className='col-lg-12'>
        <WhatsUpTodayTableWidget className='card-xl-stretch mb-xl-8' />
      </div>    
    </div>*/}

    {auth?.currentRole!==RoleModel.InstitutionManager && auth?.currentRole!==RoleModel.Admin && auth?.currentRole!==RoleModel.Seller  ?
    ( 
    <div className='row'>
      <div className='col-lg-6'>
      <AnnouncementTableWidgetWrapper />
      </div>      

      <div className='col-lg-6'>
      <MessageTableWidget className='card-xl-stretch mb-xl-8' />
      </div>    
    </div>
    ) : 
    <> </>
    } 

    {/* begin::Row */}
    {auth?.currentRole===RoleModel.SchoolManager || 
    auth?.currentRole===RoleModel.InstitutionManager ||
    auth?.currentRole===RoleModel.Customer ||
    auth?.currentRole===RoleModel.Person    ?
    ( 
    <div className='row'>
  

      <div className='col-lg-4'>
      <AssignmentLessonCountChartWidget className='card-xl-stretch mb-xl-8' />
      </div>

      <div className='col-lg-4'>
      <CustomerLessonCountChartWidget className='card-xl-stretch mb-xl-8' />
      </div>

      <div className='col-lg-4'>
      <CustomerPaymentDoneLessonCountChartWidget className='card-xl-stretch mb-xl-8' />
      </div>


      <div className='col-lg-4'>
      <StudyLessonCountChartWidget className='card-xl-stretch mb-xl-8' />
      </div>

      <div className='col-lg-4'>
      <AnnouncementLessonCountChartWidget className='card-xl-stretch mb-xl-8' />
      </div>

      <div className='col-lg-4'>
      <ExamCountChartWidget className='card-xl-stretch mb-xl-8' />
      </div>

      <div className='col-lg-4'>
      <EmployeeLessonCountChartWidget className='card-xl-stretch mb-xl-8' />
      </div>

      <div className='col-lg-4'>
      <ClubLessonCountChartWidget className='card-xl-stretch mb-xl-8' />
      </div>

      <div className='col-lg-4'>
      <MedicalInterventionLessonCountChartWidget className='card-xl-stretch mb-xl-8' />
      </div>
    </div>
    ) : 
    <> </>
    } 
    {/* end::Row */}

  </>
  )
}

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'dtq'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
