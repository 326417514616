import {useState, useEffect} from 'react'
import {useQuery} from 'react-query'
import {MessageEditModalForm} from './MessageEditModalForm'
import {isNotEmpty, MESSAGES_QUERIES} from '../../../../../_metronic/helpers'
import {useListView} from '../core/ListViewProvider'
import {getMessageById} from '../core/_requests'
import {getMessageTypes} from '../../../messagetype/messagetypes-list/core/_requests'
import {MessageType, initialMessageTypes} from '../../../messagetype/messagetypes-list/core/_models'
import {getUsers} from '../../../apps/user-management/users-list/core/_requests'
import {User, initialUsers} from '../../../apps/user-management/users-list/core/_models'
import {useAuth} from '../../../auth'

const MessageEditModalFormWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  
  const {currentUser} = useAuth()
  const [messagetypes, setMessageTypes] = useState<MessageType[]>(initialMessageTypes)
  const [users, setUsers] = useState<User[]>(initialUsers)
  
  const {
    isLoading,
    data: message,
    error,
  } = useQuery(
    `${MESSAGES_QUERIES.MESSAGES_LIST}-message-${itemIdForUpdate}`,
    () => {
      return getMessageById(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )
  
  useEffect(() => {   
    getMessageTypes('&subinstitutionId=' + currentUser?.subinstitutionId).then((values) => { setMessageTypes(values.data!) }) 
    
    getUsers('&subinstitutionId=' + currentUser?.subinstitutionId).then((values) => { setUsers(values.data!) }) 
  
  }, [])

  

  if (!itemIdForUpdate) {
    return <MessageEditModalForm isMessageLoading={isLoading} message={{id: undefined}} messagetypes={messagetypes} users={users}  />
  }

  if (!isLoading && !error && message) {
    return <MessageEditModalForm isMessageLoading={isLoading} message={message} messagetypes={messagetypes} users={users}  />
  }

  return null
}

export {MessageEditModalFormWrapper}
