import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'
import {ChartModel} from '../../../../../_metronic/partials/widgets/charts/_models'
import {MedicalIntervention, MedicalInterventionsQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const GET_MEDICALINTERVENTION_URL = `${API_URL}/medicalintervention/getmedicalintervention`
const GET_MEDICALINTERVENTION_COUNT_CHART_URL = `${API_URL}/medicalintervention/getmedicalinterventioncountchart`
const GET_MEDICALINTERVENTION_LESSON_COUNT_CHART_URL = `${API_URL}/medicalintervention/getmedicalinterventionlessoncountchart`
const CREATE_MEDICALINTERVENTION_URL = `${API_URL}/medicalintervention/addmedicalintervention`
const UPDATE_MEDICALINTERVENTION_URL = `${API_URL}/medicalintervention/updatemedicalintervention`
const DELETE_MEDICALINTERVENTION_URL = `${API_URL}/medicalintervention/deletemedicalintervention`
const GET_MEDICALINTERVENTIONS_URL = `${API_URL}/medicalintervention/getmedicalinterventions`
const GET_CUSTOMER_MEDICALINTERVENTIONS_URL = `${API_URL}/medicalintervention/getcustomermedicalinterventions`

const getMedicalInterventions = (query: string): Promise<MedicalInterventionsQueryResponse> => {
  return axios
    .get(`${GET_MEDICALINTERVENTIONS_URL}?${query}`)
    .then((d: AxiosResponse<MedicalInterventionsQueryResponse>) => d.data)
}

const getCustomerMedicalInterventions = (query: string): Promise<MedicalInterventionsQueryResponse> => {
  return axios
    .get(`${GET_CUSTOMER_MEDICALINTERVENTIONS_URL}?${query}`)
    .then((d: AxiosResponse<MedicalInterventionsQueryResponse>) => d.data)
}

const getMedicalInterventionById = (id: ID): Promise<MedicalIntervention | undefined> => {
  return axios
    .get(`${GET_MEDICALINTERVENTION_URL}/${id}`)
    .then((response: AxiosResponse<Response<MedicalIntervention>>) => response.data)
    .then((response: Response<MedicalIntervention>) => response.data)
}

const getMedicalInterventionCountChart = (query: string): Promise<ChartModel | undefined> => {
  return axios
    .get(`${GET_MEDICALINTERVENTION_COUNT_CHART_URL}?${query}`)
    .then((response: AxiosResponse<Response<ChartModel>>) => response.data)
    .then((response: Response<ChartModel>) => response.data)
}

const getMedicalInterventionLessonCountChart = (query: string): Promise<ChartModel | undefined> => {
  return axios
    .get(`${GET_MEDICALINTERVENTION_LESSON_COUNT_CHART_URL}?${query}`)
    .then((response: AxiosResponse<Response<ChartModel>>) => response.data)
    .then((response: Response<ChartModel>) => response.data)
}


const createMedicalIntervention = (medicalintervention: MedicalIntervention): Promise<MedicalIntervention | undefined> => {
  return axios
    .post(CREATE_MEDICALINTERVENTION_URL, medicalintervention)
    .then((response: AxiosResponse<Response<MedicalIntervention>>) => response.data)
    .then((response: Response<MedicalIntervention>) => response.data)
}

const updateMedicalIntervention = (medicalintervention: MedicalIntervention): Promise<MedicalIntervention | undefined> => {
  return axios
    .put(`${UPDATE_MEDICALINTERVENTION_URL}/${medicalintervention.id}`, medicalintervention)
    .then((response: AxiosResponse<Response<MedicalIntervention>>) => response.data)
    .then((response: Response<MedicalIntervention>) => response.data)
}

const deleteMedicalIntervention = (medicalinterventionId: ID): Promise<void> => {
  return axios.delete(`${DELETE_MEDICALINTERVENTION_URL}/${medicalinterventionId}`).then(() => {})
}

const deleteSelectedMedicalInterventions = (medicalinterventionIds: Array<ID>): Promise<void> => {
  const requests = medicalinterventionIds.map((id) => axios.delete(`${DELETE_MEDICALINTERVENTION_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {getMedicalInterventions, getCustomerMedicalInterventions, deleteMedicalIntervention, getMedicalInterventionCountChart, getMedicalInterventionLessonCountChart, deleteSelectedMedicalInterventions, getMedicalInterventionById, createMedicalIntervention, updateMedicalIntervention}
