import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'
import {ChartModel} from '../../../../../_metronic/partials/widgets/charts/_models'
import {Shuttle, ShuttlesQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const GET_SHUTTLE_URL = `${API_URL}/shuttle/getshuttle`
const GET_SHUTTLE_COUNT_CHART_URL = `${API_URL}/shuttle/getshuttlecountchart`
const GET_SHUTTLE_LESSON_COUNT_CHART_URL = `${API_URL}/shuttle/getshuttlelessoncountchart`
const CREATE_SHUTTLE_URL = `${API_URL}/shuttle/addshuttle`
const UPDATE_SHUTTLE_URL = `${API_URL}/shuttle/updateshuttle`
const DELETE_SHUTTLE_URL = `${API_URL}/shuttle/deleteshuttle`
const GET_SHUTTLES_URL = `${API_URL}/shuttle/getshuttles`
const GET_CUSTOMER_SHUTTLES_URL = `${API_URL}/shuttle/getcustomershuttles`

const getShuttles = (query: string): Promise<ShuttlesQueryResponse> => {
  return axios
    .get(`${GET_SHUTTLES_URL}?${query}`)
    .then((d: AxiosResponse<ShuttlesQueryResponse>) => d.data)
}

const getCustomerShuttles = (query: string): Promise<ShuttlesQueryResponse> => {
  return axios
    .get(`${GET_CUSTOMER_SHUTTLES_URL}?${query}`)
    .then((d: AxiosResponse<ShuttlesQueryResponse>) => d.data)
}

const getShuttleById = (id: ID): Promise<Shuttle | undefined> => {
  return axios
    .get(`${GET_SHUTTLE_URL}/${id}`)
    .then((response: AxiosResponse<Response<Shuttle>>) => response.data)
    .then((response: Response<Shuttle>) => response.data)
}

const getShuttleCountChart = (query: string): Promise<ChartModel | undefined> => {
  return axios
    .get(`${GET_SHUTTLE_COUNT_CHART_URL}?${query}`)
    .then((response: AxiosResponse<Response<ChartModel>>) => response.data)
    .then((response: Response<ChartModel>) => response.data)
}

const getShuttleLessonCountChart = (query: string): Promise<ChartModel | undefined> => {
  return axios
    .get(`${GET_SHUTTLE_LESSON_COUNT_CHART_URL}?${query}`)
    .then((response: AxiosResponse<Response<ChartModel>>) => response.data)
    .then((response: Response<ChartModel>) => response.data)
}

const createShuttle = (shuttle: Shuttle): Promise<Shuttle | undefined> => {
  return axios
    .post(CREATE_SHUTTLE_URL, shuttle)
    .then((response: AxiosResponse<Response<Shuttle>>) => response.data)
    .then((response: Response<Shuttle>) => response.data)
}

const updateShuttle = (shuttle: Shuttle): Promise<Shuttle | undefined> => {
  return axios
    .put(`${UPDATE_SHUTTLE_URL}/${shuttle.id}`, shuttle)
    .then((response: AxiosResponse<Response<Shuttle>>) => response.data)
    .then((response: Response<Shuttle>) => response.data)
}

const deleteShuttle = (shuttleId: ID): Promise<void> => {
  return axios.delete(`${DELETE_SHUTTLE_URL}/${shuttleId}`).then(() => {})
}

const deleteSelectedShuttles = (shuttleIds: Array<ID>): Promise<void> => {
  const requests = shuttleIds.map((id) => axios.delete(`${DELETE_SHUTTLE_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {getShuttles, getCustomerShuttles, deleteShuttle, getShuttleLessonCountChart, getShuttleCountChart,  deleteSelectedShuttles, getShuttleById, createShuttle, updateShuttle}
