import React, {FC, createContext, useContext, useState} from 'react'
import {WithChildren, isNotEmpty} from '../../../helpers'

const YEAR_CONFIG_KEY = process.env.REACT_APP_YEAR_CONFIG_KEY || 'OpraziAcademicYearConfig'
const YEAR_NAME_CONFIG_KEY = process.env.REACT_APP_YEAR_CONFIG_KEY || 'OpraziAcademicYearNameConfig'


const initialState = {
  selectedYear: 0,
  selectedYearName: ''
}

function getConfig() {
  const year = localStorage.getItem(YEAR_CONFIG_KEY)
  if (isNotEmpty(year)) {
    try {
      return JSON.parse(String(year)).selectedYear
    } catch (er) {
      console.error(er)
    }
  }
  return 0
}

function getYearNameConfig() {
  const year = localStorage.getItem(YEAR_NAME_CONFIG_KEY)
  if (isNotEmpty(year)) {
    try {
      return JSON.parse(String(year)).selectedYearName
    } catch (er) {
      console.error(er)
    }
  }
  return ''
}

// Side effect
export function setYear(year: string) {
  localStorage.setItem(YEAR_CONFIG_KEY, JSON.stringify({selectedYear: year}))
}

export function setYearName(yearName: string) {
  localStorage.setItem(YEAR_NAME_CONFIG_KEY, JSON.stringify({selectedYearName: yearName}))
}

const YearContext = createContext(initialState)

const useYear = () => {
  return useContext(YearContext).selectedYear
}

const useYearName = () => {
  return useContext(YearContext).selectedYearName
}

const YearsProvider: FC<WithChildren> = ({children}) => {
  const selectedYear = getConfig()
  const selectedYearName = getYearNameConfig()
  return <YearContext.Provider value={{selectedYear : selectedYear, selectedYearName : selectedYearName}}>{children}</YearContext.Provider>
}

export {YearsProvider, useYear, useYearName}
