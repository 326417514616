/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState, useRef} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSS, getCSSVariableValue} from '../../../../assets/ts/_utils'
import {useThemeMode} from '../../../layout/theme-mode/ThemeModeProvider'
import {useIntl} from "react-intl";
import {getExamResultViewLessonChart} from '../../../../../app/modules/examresult/examresults-list/core/_requests'
import {ChartModel, initialChartModel} from '../../../../../_metronic/partials/widgets/charts/_models'
import {ID} from '../../../../../_metronic/helpers'

type Props = {
  className: string,
  examResultId: ID,
  examLessonFormId: ID,
  examId: ID
}

const ExamResultViewLessonChartWidget: React.FC<Props> = ({className, examResultId, examLessonFormId, examId}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()
  const intl = useIntl();

  useEffect(() => {
    if(examResultId !== undefined &&
      examLessonFormId !== undefined &&
      examId !== undefined
      )
        getExamResultViewLessonChart(examResultId, examLessonFormId, examId).then((value) => { 
          setExamCountChart(value); 
        }) 
  }, [examResultId, examLessonFormId, examId])

  const [examCountChart, setExamCountChart] = useState<ChartModel | undefined>(initialChartModel)
  const refreshMode = () => {
    if (!chartRef.current) {
      return
    }
    const height = parseInt(getCSS(chartRef.current, 'height'))
    const chart = new ApexCharts(chartRef.current, getChartOptions(height, examCountChart))
      
    if (chart) {
      chart.render()
    }

    return chart      
  }

  useEffect(() => {
      const chart = refreshMode()
    
      return () => {
        if (chart) {
          chart.destroy()
        }
      }

  }, [chartRef, mode, examCountChart, examLessonFormId])

  function getChartOptions(height: number, examCountChart : ChartModel | undefined): ApexOptions {
    const labelColor = getCSSVariableValue('--kt-gray-500')
    const borderColor = getCSSVariableValue('--kt-gray-200')
    const baseColor = getCSSVariableValue('--kt-warning')
    const lightColor = getCSSVariableValue('--kt-info-light')
    const secondaryColor = getCSSVariableValue('--kt-warning')
    
    return {
      series: [
        {
          name: intl.formatMessage({id: 'cqq'}),
          data: examCountChart?.series1 as Array<number>,
        }
      ],
      chart: {
        fontFamily: 'inherit',
        type: 'bar',
        width: 80,
        height: 250,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {},
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        type: 'solid',
        opacity: 1,
      },
      stroke: {
        curve: 'smooth',
        show: true,
        width: 3,
        colors: [baseColor],
      },
      xaxis: {
        categories: examCountChart?.categories as Array<string>,
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          style: {
            colors: labelColor,
            fontSize: '10px',
          },
          rotate: -90
        },
        crosshairs: {
          position: 'front',
          stroke: {
            color: baseColor,
            width: 1,
            dashArray: 3,
          },
        },
        tooltip: {
          enabled: true,
          formatter: undefined,
          offsetY: 0,
          style: {
            fontSize: '12px',
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: labelColor,
            fontSize: '9px',
          },
        },
      },
      states: {
        normal: {
          filter: {
            type: 'none',
            value: 0,
          },
        },
        hover: {
          filter: {
            type: 'none',
            value: 0,
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: 'none',
            value: 0,
          },
        },
      },
      tooltip: {
        style: {
          fontSize: '12px',
        },
        y: {
          formatter: function (val) {
            return val + ' '
          },
        },
      },
      colors: [baseColor],
      grid: {
        borderColor: borderColor,
        strokeDashArray: 4,
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      markers: {
        strokeColors: baseColor,
        strokeWidth: 3,
      },
    }
  }

  return (
    <div className={`card ${className}`}>
      {/* begin::Body */}
      <div className='card-body p-0'>
        {/* begin::Chart */}
        <div ref={chartRef} id='kt_charts_widget_3_chart' style={{height: '250px'}}></div>
        {/* end::Chart */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export {ExamResultViewLessonChartWidget}


