import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'
import {ExamResult, ExamResultsQueryResponse} from './_models'
import {ChartModel} from '../../../../../_metronic/partials/widgets/charts/_models'

const API_URL = process.env.REACT_APP_API_URL
const GET_EXAMRESULT_URL = `${API_URL}/examresult/getexamresult`
const GET_EXAMRESULT_COUNT_URL = `${API_URL}/examresult/getcustomerexamresultcount`
const GET_EXAMRESULT_VIEW_LESSON_CHART_URL = `${API_URL}/examresult/getexamresultviewlessonchart`
const CREATE_EXAMRESULT_URL = `${API_URL}/examresult/addexamresult`
const UPDATE_EXAMRESULT_URL = `${API_URL}/examresult/updateexamresult`
const DELETE_EXAMRESULT_URL = `${API_URL}/examresult/deleteexamresult`
const GET_EXAMRESULTS_URL = `${API_URL}/examresult/getexamresults`

const getExamResults = (query: string): Promise<ExamResultsQueryResponse> => {
  return axios
    .get(`${GET_EXAMRESULTS_URL}?${query}`)
    .then((d: AxiosResponse<ExamResultsQueryResponse>) => d.data)
}

const getExamResultById = (id: ID): Promise<ExamResult | undefined> => {
  return axios
    .get(`${GET_EXAMRESULT_URL}/${id}`)
    .then((response: AxiosResponse<Response<ExamResult>>) => response.data)
    .then((response: Response<ExamResult>) => response.data)
}



const getCustomerExamResultCount = (query: string): Promise<number | undefined> => {
  return axios
    .get(`${GET_EXAMRESULT_COUNT_URL}?${query}`)
    .then((response: AxiosResponse<Response<number>>) => response.data)
    .then((response: Response<number>) => response.data)
}

const getExamResultViewLessonChart = (examResultId: ID, examLessonFormId: ID, examId: ID): Promise<ChartModel | undefined> => {
  return axios
    .get(`${GET_EXAMRESULT_VIEW_LESSON_CHART_URL}?examResultId=${examResultId}&examLessonFormId=${examLessonFormId}&examId=${examId}`)
    .then((response: AxiosResponse<Response<ChartModel>>) => response.data)
    .then((response: Response<ChartModel>) => response.data)
}

const createExamResult = (examresult: ExamResult): Promise<ExamResult | undefined> => {
  return axios
    .post(CREATE_EXAMRESULT_URL, examresult)
    .then((response: AxiosResponse<Response<ExamResult>>) => response.data)
    .then((response: Response<ExamResult>) => response.data)
}

const updateExamResult = (examresult: ExamResult): Promise<ExamResult | undefined> => {
  return axios
    .put(`${UPDATE_EXAMRESULT_URL}/${examresult.id}`, examresult)
    .then((response: AxiosResponse<Response<ExamResult>>) => response.data)
    .then((response: Response<ExamResult>) => response.data)
}

const deleteExamResult = (examresultId: ID): Promise<void> => {
  return axios.delete(`${DELETE_EXAMRESULT_URL}/${examresultId}`).then(() => {})
}

const deleteSelectedExamResults = (examresultIds: Array<ID>): Promise<void> => {
  const requests = examresultIds.map((id) => axios.delete(`${DELETE_EXAMRESULT_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {getExamResults, getCustomerExamResultCount, deleteExamResult, deleteSelectedExamResults, getExamResultById, getExamResultViewLessonChart, createExamResult, updateExamResult}
