import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'
import {ChartModel} from '../../../../../_metronic/partials/widgets/charts/_models'
import {Attendance, AttendancesQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const GET_ATTENDANCE_URL = `${API_URL}/attendance/getattendance`
const GET_ATTENDANCE_COUNT_CHART_URL = `${API_URL}/attendance/getattendancecountchart`
const GET_ATTENDANCE_LESSON_COUNT_CHART_URL = `${API_URL}/attendance/getattendancelessoncountchart`
const CREATE_ATTENDANCE_URL = `${API_URL}/attendance/addattendance`
const UPDATE_ATTENDANCE_URL = `${API_URL}/attendance/updateattendance`
const DELETE_ATTENDANCE_URL = `${API_URL}/attendance/deleteattendance`
const GET_ATTENDANCES_URL = `${API_URL}/attendance/getattendances`
const GET_CUSTOMER_ATTENDANCES_URL = `${API_URL}/attendance/getcustomerattendances`

const getAttendances = (query: string): Promise<AttendancesQueryResponse> => {
  return axios
    .get(`${GET_ATTENDANCES_URL}?${query}`)
    .then((d: AxiosResponse<AttendancesQueryResponse>) => d.data)
}

const getCustomerAttendances = (query: string): Promise<AttendancesQueryResponse> => {
  return axios
    .get(`${GET_CUSTOMER_ATTENDANCES_URL}?${query}`)
    .then((d: AxiosResponse<AttendancesQueryResponse>) => d.data)
}

const getAttendanceById = (id: ID): Promise<Attendance | undefined> => {
  return axios
    .get(`${GET_ATTENDANCE_URL}/${id}`)
    .then((response: AxiosResponse<Response<Attendance>>) => response.data)
    .then((response: Response<Attendance>) => response.data)
}

const getAttendanceCountChart = (query: string): Promise<ChartModel | undefined> => {
  return axios
    .get(`${GET_ATTENDANCE_COUNT_CHART_URL}?${query}`)
    .then((response: AxiosResponse<Response<ChartModel>>) => response.data)
    .then((response: Response<ChartModel>) => response.data)
}

const getAttendanceLessonCountChart = (query: string): Promise<ChartModel | undefined> => {
  return axios
    .get(`${GET_ATTENDANCE_LESSON_COUNT_CHART_URL}?${query}`)
    .then((response: AxiosResponse<Response<ChartModel>>) => response.data)
    .then((response: Response<ChartModel>) => response.data)
}

const createAttendance = (attendance: Attendance): Promise<Attendance | undefined> => {
  return axios
    .post(CREATE_ATTENDANCE_URL, attendance)
    .then((response: AxiosResponse<Response<Attendance>>) => response.data)
    .then((response: Response<Attendance>) => response.data)
}

const updateAttendance = (attendance: Attendance): Promise<Attendance | undefined> => {
  return axios
    .put(`${UPDATE_ATTENDANCE_URL}/${attendance.id}`, attendance)
    .then((response: AxiosResponse<Response<Attendance>>) => response.data)
    .then((response: Response<Attendance>) => response.data)
}

const deleteAttendance = (attendanceId: ID): Promise<void> => {
  return axios.delete(`${DELETE_ATTENDANCE_URL}/${attendanceId}`).then(() => {})
}

const deleteSelectedAttendances = (attendanceIds: Array<ID>): Promise<void> => {
  const requests = attendanceIds.map((id) => axios.delete(`${DELETE_ATTENDANCE_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {getAttendances, getCustomerAttendances, deleteAttendance, getAttendanceCountChart, getAttendanceLessonCountChart, deleteSelectedAttendances, getAttendanceById, createAttendance, updateAttendance}
