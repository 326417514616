import {KTSVG} from '../../../../../_metronic/helpers'
import {useListView} from '../core/ListViewProvider'
import { useIntl } from "react-intl";


const AnnouncementViewModalHeader = () => {
  const {setItemIdForSecondOperation} = useListView()
  const intl = useIntl();

  return (
    <div className='modal-header'>
      {/* begin::Modal title */}
      <h2 className='fw-bolder'>{intl.formatMessage({ id: "ow5" })}</h2>
      {/* end::Modal title */}

      {/* begin::Close */}
      <div
        className='btn btn-icon btn-sm btn-active-icon-primary'
        data-kt-announcements-modal-action='close'
        onClick={() => setItemIdForSecondOperation ? setItemIdForSecondOperation(undefined) : <></>}
        style={{cursor: 'pointer'}}
      >
        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
      </div>
      {/* end::Close */}
    </div>
  )
}

export {AnnouncementViewModalHeader}
