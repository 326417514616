/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState, useRef} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSS, getCSSVariableValue} from '../../../../assets/ts/_utils'
import {useThemeMode} from '../../../layout/theme-mode/ThemeModeProvider'
import {useIntl} from "react-intl";
import {useAuth} from '../../../../../app/modules/auth'
import {getSubinstitutionCountChart} from '../../../../../app/modules/subinstitution/subinstitutions-list/core/_requests'
import {ChartModel, initialChartModel} from '../../../../../_metronic/partials/widgets/charts/_models'
import {useYear} from '../../../../../_metronic/partials/layout/header-menus/YearsManager'
import {RoleModel} from '../../../../../app/modules/auth/core/_models'

type Props = {
  className: string
}

const SubinstitutionCountChartWidget: React.FC<Props> = ({className}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()
  const intl = useIntl();
  const {currentUser, auth} = useAuth()
  const currentYearId = useYear()
  useEffect(() => {
    let query = ''
    if(auth?.currentRole===RoleModel.InstitutionManager)
      query = "&institutionId=" + currentUser?.institutionId + "&academicYearId=" + currentYearId
    else if(auth?.currentRole===RoleModel.Customer || auth?.currentRole===RoleModel.Person)
      query = "&customerId=" + currentUser?.customerId + "&academicYearId=" + currentYearId
    else 
      query = "&institutionId=" + currentUser?.institutionId + "&subinstitutionId=" + currentUser?.subinstitutionId + "&academicYearId=" + currentYearId
    getSubinstitutionCountChart(query).then((value) => { 
      setSubinstitutionCountChart(value); 
    }) 
  }, [])

  const [subinstitutionCountChart, setSubinstitutionCountChart] = useState<ChartModel | undefined>(initialChartModel)
  const refreshMode = () => {
    if (!chartRef.current) {
      return
    }
    const height = parseInt(getCSS(chartRef.current, 'height'))
    const chart = new ApexCharts(chartRef.current, getChartOptions(height, subinstitutionCountChart))
      
    if (chart) {
      chart.render()
    }

    return chart      
  }

  useEffect(() => {
      const chart = refreshMode()
    
      return () => {
        if (chart) {
          chart.destroy()
        }
      }

  }, [chartRef, mode, subinstitutionCountChart])

  function getChartOptions(height: number, subinstitutionCountChart : ChartModel | undefined): ApexOptions {
    const labelColor = getCSSVariableValue('--kt-gray-500')
    const borderColor = getCSSVariableValue('--kt-gray-200')
    const baseColor = getCSSVariableValue('--kt-info')
    const lightColor = getCSSVariableValue('--kt-info-light')
    const secondaryColor = getCSSVariableValue('--kt-warning')
    
    return {
      series: [
        {
          name: intl.formatMessage({id: 'SUBINSTITUTIONDASHBOARD.SUBINSTITUTIONCOUNTCHART.SERIES1.NAME'}),
          data: subinstitutionCountChart?.series1 as Array<number>,
        }
      ],
      chart: {
        fontFamily: 'inherit',
        type: 'line',
        height: 350,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {},
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        type: 'solid',
        opacity: 1,
      },
      stroke: {
        curve: 'smooth',
        show: true,
        width: 3,
        colors: [baseColor],
      },
      xaxis: {
        categories: subinstitutionCountChart?.categories as Array<string>,
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          style: {
            colors: labelColor,
            fontSize: '12px',
          },
        },
        crosshairs: {
          position: 'front',
          stroke: {
            color: baseColor,
            width: 1,
            dashArray: 3,
          },
        },
        tooltip: {
          enabled: true,
          formatter: undefined,
          offsetY: 0,
          style: {
            fontSize: '12px',
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: labelColor,
            fontSize: '12px',
          },
        },
      },
      states: {
        normal: {
          filter: {
            type: 'none',
            value: 0,
          },
        },
        hover: {
          filter: {
            type: 'none',
            value: 0,
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: 'none',
            value: 0,
          },
        },
      },
      tooltip: {
        style: {
          fontSize: '12px',
        },
        y: {
          formatter: function (val) {
            return val + ' '
          },
        },
      },
      colors: [baseColor],
      grid: {
        borderColor: borderColor,
        strokeDashArray: 4,
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      markers: {
        strokeColors: baseColor,
        strokeWidth: 3,
      },
    }
  }

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{intl.formatMessage({id: '9ax'})}</span>

          <span className='text-muted fw-semibold fs-7'>{intl.formatMessage({id: 'yl1'})}</span>
        </h3>
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body'>
        {/* begin::Chart */}
        <div ref={chartRef} id='kt_charts_widget_3_chart' style={{height: '350px'}}></div>
        {/* end::Chart */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export {SubinstitutionCountChartWidget}


