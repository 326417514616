/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import {ID, toAbsoluteUrl} from '../../../helpers'
import {getFileContent} from '../../../../app/modules/file/files-list/core/_requests'

type Props = {
  id: ID
  width?: string
}

const ImageWidget: React.FC<Props> = ({id, width = 'auto'}) => {
  const [image, setImage] = useState(toAbsoluteUrl('/media/avatars/blank.png'))
  useEffect(() =>  {
    if(id !== undefined && id !== null)
      getFileContent(id).then((fileContent) => {     
        if(fileContent !== undefined)
        {  
          var logoUrlBlob = new Blob([fileContent],{type: fileContent.type}, )
          setImage(URL.createObjectURL(logoUrlBlob))
        }
      })
    else 
      setImage(toAbsoluteUrl('/media/avatars/blank.png'))
  }, [id])
  
  return (
    <img src={image} alt='' width={width} />
  )
}

export {ImageWidget}
