/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import {ID, KTSVG} from '../../../../helpers'
import {getAnnouncements} from '../../../../../app/modules/announcement/announcements-list/core/_requests'
import {useAuth} from '../../../../../app/modules/auth'
import {Announcement, initialAnnouncements} from '../../../../../app/modules/announcement/announcements-list/core/_models'
import {useListView} from '../../../../../app/modules/announcement/announcements-list/core/ListViewProvider'
import {AnnouncementEditModal} from '../../../../../app/modules/announcement/announcements-list/announcement-edit-modal/AnnouncementEditModal'
import { useIntl } from "react-intl";

const AnnouncementTableWidget = () => {
  const {setItemIdForSecondOperation} = useListView()
  const intl = useIntl();
  const {currentUser} = useAuth()
  const [announcements, setAnnouncements] = useState<Announcement[]>(initialAnnouncements)

  useEffect(() => {
    getAnnouncements('&subinstitutionId=' + currentUser?.subinstitutionId + '&isCustomer=' + (currentUser?.customerId !== null) + '&isEmployee=' + (currentUser?.employeeId !== null) + '&isPerson=' + (currentUser?.personId !== null)).then((values) => { setAnnouncements(values.data!) })     
  }, [])

  const openEditModal = (id:ID) => {
    if(setItemIdForSecondOperation)
      setItemIdForSecondOperation(id)
  }

  return (
    <>
    <div className={`card card-xl-stretch mb-xl-8`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{intl.formatMessage({id: 'ow5'})}</span>
          <span className='text-muted fw-semibold fs-7'>{intl.formatMessage({id: 'we4'})}</span>
        </h3>
        
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table align-middle gs-0 gy-5'>
            {/* begin::Table head */}
            <thead>
              <tr>
                <th className='p-0 min-w-360px'></th>
                <th className='p-0 min-w-40px'></th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
            {
              announcements.map(announcement => 
              <tr key={announcement?.id}>
                <td>
                  <a onClick={() => openEditModal(announcement?.id)} className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                    {announcement.title}
                  </a>
                  <span className='text-muted fw-semibold d-block fs-7'>{announcement?.content !== undefined ? (announcement?.content?.length > 50 ? announcement?.content?.substring(0, 50) + '...' : announcement?.content) : ''}</span>
                </td>
                <td className='text-end'>
                  <a onClick={() => openEditModal(announcement?.id)} className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'>
                    <KTSVG path='/media/icons/duotune/arrows/arr064.svg' className='svg-icon-2' />
                  </a>
                </td>
              </tr>
              )
              }
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
    </div>         
    </>
  )
}

export {AnnouncementTableWidget}
