import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'
import {Chat, Group, UnreadChatCounts, ChatsQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const GET_CHAT_URL = `${API_URL}/chat/getchat`
const GET_LAST_CHAT_IN_URL = `${API_URL}/chat/getlastchatin`
const GET_UNREAD_CHAT_COUNTS_URL = `${API_URL}/chat/getunreadchatcounts`
const CREATE_CHAT_URL = `${API_URL}/chathub/addchat`
const UPDATE_CHAT_URL = `${API_URL}/chathub/updatechat`
const JOIN_GROUP_URL = `${API_URL}/chathub/joingroup`
const GET_CHATS_URL = `${API_URL}/chat/getchats`

const getChats = (query: string): Promise<ChatsQueryResponse> => {
  return axios
    .get(`${GET_CHATS_URL}?${query}`)
    .then((d: AxiosResponse<ChatsQueryResponse>) => d.data)
}

const getChatById = (id: ID): Promise<Chat | undefined> => {
  return axios
    .get(`${GET_CHAT_URL}/${id}`)
    .then((response: AxiosResponse<Response<Chat>>) => response.data)
    .then((response: Response<Chat>) => response.data)
}

const getLastChatIn = (userId: ID): Promise<Chat | undefined> => {
  return axios
    .get(`${GET_LAST_CHAT_IN_URL}/${userId}`)
    .then((response: AxiosResponse<Response<Chat>>) => response.data)
    .then((response: Response<Chat>) => response.data)
}

const getUnreadChatCounts = (userId: ID): Promise<UnreadChatCounts[] | undefined> => {
  return axios
    .get(`${GET_UNREAD_CHAT_COUNTS_URL}/${userId}`)
    .then((response: AxiosResponse<Response<UnreadChatCounts[]>>) => response.data)
    .then((response: Response<UnreadChatCounts[]>) => response.data)
}

const createChat = (chat: Chat): Promise<Chat | undefined> => {
  return axios
    .post(CREATE_CHAT_URL, chat)
    .then((response: AxiosResponse<Response<Chat>>) => response.data)
    .then((response: Response<Chat>) => response.data)
}

const updateChat = (chat: Chat): Promise<Chat | undefined> => {
  return axios
    .put(`${UPDATE_CHAT_URL}/${chat.id}`, chat)
    .then((response: AxiosResponse<Response<Chat>>) => response.data)
    .then((response: Response<Chat>) => response.data)
}

const joinGroup = (group: Group): Promise<Group | undefined> => {
  return axios
    .post(JOIN_GROUP_URL, group)
    .then((response: AxiosResponse<Response<Group>>) => response.data)
    .then((response: Response<Group>) => response.data)
}


export {getChats, getChatById, getLastChatIn, getUnreadChatCounts, createChat, updateChat, joinGroup}
