import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'
import {File, FileContent, FilesQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const GET_FILE_CONTENT_URL = `${API_URL}/file/getfilecontent`
const GET_FILE_URL = `${API_URL}/file/getfile`
const CREATE_FILE_URL = `${API_URL}/file/addfile`
const UPDATE_FILE_URL = `${API_URL}/file/updatefile`
const DELETE_FILE_URL = `${API_URL}/file/deletefile`
const GET_FILES_URL = `${API_URL}/file/getfiles`
const GET_GALLERY_FILES_URL = `${API_URL}/file/getgalleryfiles`
const UPLOAD_FILE_URL = `${API_URL}/file/uploadfile`

const getFiles = (query: string): Promise<FilesQueryResponse> => {
  return axios
    .get(`${GET_FILES_URL}?${query}`)
    .then((d: AxiosResponse<FilesQueryResponse>) => d.data)
}

const getGalleryFiles = (query: string): Promise<FilesQueryResponse> => {
  return axios
    .get(`${GET_GALLERY_FILES_URL}?${query}`)
    .then((d: AxiosResponse<FilesQueryResponse>) => d.data)
}

const getFileContent = (id: ID): Promise<Blob | undefined> => {
  return axios
    .get(`${GET_FILE_CONTENT_URL}/${id}`, {responseType: 'blob'})
    .then((response: AxiosResponse<Blob>) => response.data)
    .then((response: Blob) => response)
}

const getFileById = (id: ID): Promise<File | undefined> => {
  return axios
    .get(`${GET_FILE_URL}/${id}`)
    .then((response: AxiosResponse<Response<File>>) => response.data)
    .then((response: Response<File>) => response.data)
}

const createFile = (file: File): Promise<File | undefined> => {
  return axios
    .post(CREATE_FILE_URL, file)
    .then((response: AxiosResponse<Response<File>>) => response.data)
    .then((response: Response<File>) => response.data)
}

const uploadFile = (fileContent: FileContent): Promise<string | undefined> => {
  const formData = new FormData();
  if(fileContent.fileContent !== undefined)
    formData.append('fileContent', fileContent.fileContent);
  return axios
    .post(`${UPLOAD_FILE_URL}`, formData)
    .then((response: AxiosResponse<Response<string>>) => response.data)
    .then((response: Response<string>) => response.data)
}

const updateFile = (file: File): Promise<File | undefined> => {
  return axios
    .put(`${UPDATE_FILE_URL}/${file.id}`, file)
    .then((response: AxiosResponse<Response<File>>) => response.data)
    .then((response: Response<File>) => response.data)
}

const deleteFile = (fileId: ID): Promise<void> => {
  return axios.delete(`${DELETE_FILE_URL}/${fileId}`).then(() => {})
}

const deleteSelectedFiles = (fileIds: Array<ID>): Promise<void> => {
  const requests = fileIds.map((id) => axios.delete(`${DELETE_FILE_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {getFiles, getGalleryFiles, deleteFile, deleteSelectedFiles, getFileContent, getFileById, createFile, uploadFile, updateFile}
