/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {getUserByToken, login, loginToH5P} from '../core/_requests'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useAuth} from '../core/Auth'
import {setAuthH5P} from '../core/AuthH5P'
import {useIntl} from 'react-intl'
import {useRole, setRole} from '../../../../_metronic/partials/layout/header-menus/RolesManager'



const initialValues = {
  email: 'demo@oprazi.com',
  password: 'demo12',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/


export function Login() {
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()
  const intl = useIntl()
  const role = useRole()
  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .email(intl.formatMessage({ id: "0yr" }))
      .min(3, intl.formatMessage({ id: "em7" }))
      .max(50, intl.formatMessage({ id: "ubm" }))
      .required(intl.formatMessage({ id: "kuu" })),
    password: Yup.string()
      .min(3, intl.formatMessage({ id: "em7" }))
      .max(50, intl.formatMessage({ id: "ubm" }))
      .required(intl.formatMessage({ id: "os8" })),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        await login(values.email, values.password, role).then(async (auth) => {
          if(auth?.currentRole)
            setRole(auth?.currentRole)
          saveAuth(auth)
          await getUserByToken(auth?.api_token!, auth?.currentRole).then((user) => {
            setCurrentUser(user)
          })

          try {
            await loginToH5P(auth?.api_token!, auth?.currentRole!).then(async (h5pUser) => {
              console.log("login h5p response " + JSON.stringify(h5pUser))
              setAuthH5P(h5pUser)
            })
            } catch (error) {
              console.error(error)
            }

        })
        
      } catch (error) {
        console.error(error)
        saveAuth(undefined)
        setStatus(intl.formatMessage({id: 'mbz'}))
        setSubmitting(false)
        setLoading(false)
      }

      
    },
  })

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>{intl.formatMessage({id: 'aj2'})}</h1>
        <div className='text-gray-400 fw-bold fs-4'>
        {intl.formatMessage({id: 'zi3'})}{' '}
          <Link to='/auth/registration' className='link-primary fw-bolder'>
          {intl.formatMessage({id: '7fk'})}
          </Link>
        </div>
      </div>
      {/* begin::Heading */}

      {formik.status ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      ) : (
        <div className='mb-10 bg-light-info p-8 rounded'>
          <div className='text-info'>
          {intl.formatMessage({id: 'ag1'})} <strong>demo@oprazi.com</strong> {intl.formatMessage({id: '4le'})} <strong>demo12</strong> {intl.formatMessage({id: 'lab'})}
          </div>
        </div>
      )}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>{intl.formatMessage({id: 'z6y'})}</label>
        <input
          placeholder='Email'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.email}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <div className='d-flex justify-content-between mt-n5'>
          <div className='d-flex flex-stack mb-2'>
            {/* begin::Label */}
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>{intl.formatMessage({id: '9xk'})}</label>
            {/* end::Label */}
            {/* begin::Link */}
            <Link
              to='/auth/forgot-password'
              className='link-primary fs-6 fw-bolder'
              style={{marginLeft: '5px'}}
            >
              {intl.formatMessage({id: 'zg9'})}
            </Link>
            {/* end::Link */}
          </div>
        </div>
        <input
          type='password'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>{intl.formatMessage({id: 'aj2'})}</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              {intl.formatMessage({id: '5wh'})}...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>

        
      </div>
      {/* end::Action */}
    </form>
  )
}
