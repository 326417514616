import {ID, Response} from '../../../../../_metronic/helpers'
export type AcademicYear = {
    id?: ID
    
    institutionId?: number
    subinstitutionId?: number
    name?: string
    startDate?: Date
    endDate?: Date
}

export type AcademicYearsQueryResponse = Response<Array<AcademicYear>>

export const initialAcademicYear: AcademicYear = {
    
    institutionId: 0,
    subinstitutionId: 0,
    name: '',
    startDate: undefined ,
    endDate: undefined
}

export const initialAcademicYears: AcademicYear[] = [{
    
    institutionId: 0,
    subinstitutionId: 0,
    name: '',
    startDate: new Date(),
    endDate: new Date()
}]
