import {useIntl} from 'react-intl'
import {RoleModel} from '../../../../app/modules/auth/core/_models'


export function RoleHelper(role : string | undefined, currentRole? : string){
  const intl = useIntl()

  let localeRole = ''
  if(role === undefined)
    return localeRole

  if(role === RoleModel.Admin && (currentRole === undefined || currentRole===RoleModel.Admin))
    localeRole = intl.formatMessage({ id: 'fcs' })
  else if(role === RoleModel.InstitutionManager)
    localeRole = intl.formatMessage({ id: 'qdo' })
  else if(role === RoleModel.SchoolManager)
    localeRole = intl.formatMessage({ id: 'nvx' })
  else if(role === RoleModel.Teacher)
    localeRole = intl.formatMessage({ id: '8hp' })
  else if(role === RoleModel.StudyManager)
    localeRole = intl.formatMessage({ id: '2rc' })
  else if(role === RoleModel.HomeworkManager)
    localeRole = intl.formatMessage({ id: 'pll' })
  else if(role === RoleModel.DeskManager)
    localeRole = intl.formatMessage({ id: 'psi' })
  else if(role === RoleModel.InstitutionAdmin)
    localeRole = intl.formatMessage({ id: '364' })
  else if(role === RoleModel.SchoolAdmin)
    localeRole = intl.formatMessage({ id: 'qom' })
  else if(role === RoleModel.Customer)
    localeRole = intl.formatMessage({ id: 'vxt' })
  else if(role === RoleModel.Person)
    localeRole = intl.formatMessage({ id: 'pb0' })
  else if(role === RoleModel.Employee)
    localeRole = intl.formatMessage({ id: '4ny' })
  else if(role === RoleModel.Accounter)
    localeRole = intl.formatMessage({ id: 'h01' })
  else if(role === RoleModel.AnnouncementManager)
    localeRole = intl.formatMessage({ id: '5l2' })
  else if(role === RoleModel.AuthManager)
    localeRole = intl.formatMessage({ id: 'pbi' })
  else if(role === RoleModel.EmployeeManager)
    localeRole = intl.formatMessage({ id: 'mjb' })
  else if(role === RoleModel.CustomerManager)
    localeRole = intl.formatMessage({ id: 'kfn' })
  else if(role === RoleModel.ClubManager)
    localeRole = intl.formatMessage({ id: '3l6' })
  else if(role === RoleModel.AttendanceManager)
    localeRole = intl.formatMessage({ id: 'p68' })
  else if(role === RoleModel.CurriculumManager)
    localeRole = intl.formatMessage({ id: 'clw' })
  else if(role === RoleModel.ExamManager)
    localeRole = intl.formatMessage({ id: 'xfa' })
  else if(role === RoleModel.SurveyManager)
    localeRole = intl.formatMessage({ id: 'z7x' })
  else if(role === RoleModel.GuidanceManager)
    localeRole = intl.formatMessage({ id: 'jnd' })
  else if(role === RoleModel.FoodManager)
    localeRole = intl.formatMessage({ id: 'c90' })
  else if(role === RoleModel.ShuttleManager)
    localeRole = intl.formatMessage({ id: 'c7h' })
  else if(role === RoleModel.HealthManager)
    localeRole = intl.formatMessage({ id: 'uvp' })
  else if(role === RoleModel.Seller)
    localeRole = intl.formatMessage({ id: '567' })
  else if(role === RoleModel.GalleryManager)
    localeRole = intl.formatMessage({ id: 'gmg' })
  else if(role === RoleModel.CourseManager)
    localeRole = intl.formatMessage({ id: 'gm1' })
  
  return localeRole 
}

