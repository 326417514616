/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {Link} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {useIntl} from 'react-intl'

const QuickLinks: FC = () => {
  const intl = useIntl()

  return (
    <>
      <div
        className='menu menu-sub menu-sub-dropdown menu-column w-250px w-lg-325px'
        data-kt-menu='true'
      >
        <div
          className='d-flex flex-column flex-center bgi-no-repeat rounded-top px-9 py-10'
          style={{backgroundImage: `url('${toAbsoluteUrl('/media/misc/pattern-1.jpg')}')`}}
        >
          <h3 className='text-white fw-bold mb-3'>{intl.formatMessage({id: 'uzk'})}</h3>

          <span className='badge bg-primary py-2 px-3'>{intl.formatMessage({id: 'j2t'})}</span>
        </div>

        <div className='row g-0'>
          <div className='col-6'>
            <a
              href='/crafted/messages/message/message'
              className='d-flex flex-column flex-center h-100 p-6 bg-hover-light border-end border-bottom'
            >
              <KTSVG
                path='/media/icons/duotune/communication/com010.svg'
                className='svg-icon-3x svg-icon-primary mb-2'
              />
              <span className='fs-5 fw-bold text-gray-800 mb-0'>{intl.formatMessage({id: 'whs'})}</span>
              <span className='fs-7 text-gray-400'>{intl.formatMessage({id: 'pw3'})}</span>
            </a>
          </div>

          <div className='col-6'>
            <a
              href='/crafted/announcements/announcement/announcement'
              className='d-flex flex-column flex-center h-100 p-6 bg-hover-light border-bottom'
            >
              <KTSVG
                path='/media/icons/duotune/communication/com004.svg'
                className='svg-icon-3x svg-icon-primary mb-2'
              />
              <span className='fs-5 fw-bold text-gray-800 mb-0'>{intl.formatMessage({id: 'ow5'})}</span>
              <span className='fs-7 text-gray-400'>{intl.formatMessage({id: 'we4'})}</span>
            </a>
          </div>

          <div className='col-6'>
            <a href='/crafted/surveys/survey/survey' className='d-flex flex-column flex-center h-100 p-6 bg-hover-light border-end'>
              <KTSVG
                path='/media/icons/duotune/abstract/abs042.svg'
                className='svg-icon-3x svg-icon-primary mb-2'
              />
              <span className='fs-5 fw-bold text-gray-800 mb-0'>{intl.formatMessage({id: '5od'})}</span>
              <span className='fs-7 text-gray-400'>{intl.formatMessage({id: 'hyj'})}</span>
            </a>
          </div>

          <div className='col-6'>
            <a href='/crafted/menus/menu/menu' className='d-flex flex-column flex-center h-100 p-6 bg-hover-light'>
              <KTSVG
                path='/media/icons/duotune/general/gen003.svg'
                className='svg-icon-3x svg-icon-primary mb-2'
              />
              <span className='fs-5 fw-bold text-gray-800 mb-0'>{intl.formatMessage({id: '39j'})}</span>
              <span className='fs-7 text-gray-400'>{intl.formatMessage({id: '5bb'})}</span>
            </a>
          </div>
        </div>
      </div>
    </>
  )
}

export {QuickLinks}
